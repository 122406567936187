import React from "react";

// Redux + AC
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import * as flowActions from "../../../../actions/flowActions";
import { makeGetPermissionsItemFromProps } from "../../../../reducers/flowItems";
import { getFlowItemClientVariablesForSelectedFlow } from "../../../../reducers/flowItemClientVariables";
import { getFlowRelationsForSelectedFlow } from "../../../../reducers/flowRelations";
import { getFlowMultiExportsByFlowItemId } from "../../../../reducers/flowMultiExports";
import type { DestinationVariable, DestinationVariableWithValue } from "../../../../types/types";
import { VariableValueType } from "../../../../types/stores/vars";
import type {
    FlowAndItemPermissions,
    FlowItemClientVariableD,
    UpdateAttribute,
    FlowRelation,
    FlowItem,
} from "../../../../types/flowTypes";
import type { MapStateToProps } from "react-redux";
import clone from "../../../../helpers/clone";

import TextField from "@material-ui/core/TextField";
import { ThemeProvider, createTheme } from "@material-ui/core";
import MaterialTable from "@material-table/core";

type Props = {
    // Passed In
    flowItemId: number,
    destinationId: number,
    isFreewheelOrMagniteDriverFile: boolean,
    variableGroups: Array<any>,
    // Redux
    destinationVariables: Array<DestinationVariableWithValue>,
    flowItemClientVariables: Array<FlowItemClientVariableD>,
    flowMultiExportsByFlowItemId: Array<number>,
    permissions: FlowAndItemPermissions,
    selectedFlow: number,
    selectedCompany: number,
    enabledFeatures: Array<string>,
    companies: Array<any>,
    flowRelations: Array<FlowRelation>,
    flowItems: { [number]: FlowItem },
    // AC
    updateAttribute: UpdateAttribute,
    newFlowItemClientVariable: (
        variableId: number,
        flowId: number,
        flowItemId: number,
        childFlowItemId: number,
        variableValue: VariableValueType
    ) => void,
    deleteFlowItemClientVariable: (id: number) => void,
    invalidateItemAndChildren: (flowItemId: number) => void,
    flow: any,
};

type State = {
    variableColumns: ?Array<any>,
    setDefaults: Boolean,
};

class FlowExportVariableEdit extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            variableColumns: null,
            setDefaults: false,
        };
    }

    componentDidMount() {
        this.createVariableColumns();
    }

    createVariableColumns = () => {
        const variableColumns = [];
        variableColumns.push({
            title: "Value",
            field: "VariableValue.ValueString",
            render: cellInfo => this.renderVariableText(cellInfo),
            headerStyle: {
                backgroundColor: "#0075bc",
                color: "#fff",
                fontWeight: 400,
                fontSize: "14px",
            },
        });
        this.setState({ variableColumns });
    };

    getDestColumns = () => {
        let variableColumns = clone(this.state.variableColumns);
        let columns = [
            {
                title: "Name",
                field: "VariableName",
                headerStyle: {
                    backgroundColor: "#0075bc",
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: "14px",
                },
            },
        ];
        if (variableColumns && variableColumns.length > 0) {
            columns.push(variableColumns[0]);
        }
        return columns;
    };

    onDestVariableChange = (variableId: number, variableValue: VariableValueType) => {
        const {
            flowItemId,
            selectedFlow,
            flowItemClientVariables,
            updateAttribute,
            newFlowItemClientVariable,
            deleteFlowItemClientVariable,
            invalidateItemAndChildren,
        } = this.props;

        const flowItemClientVariable = flowItemClientVariables.filter(
            x => x.FlowItemId == flowItemId && x.VariableId == variableId
        );

        if (flowItemClientVariable && flowItemClientVariable.length == 1) {
            if (variableValue) {
                if (variableValue.ValueString != "") {
                    updateAttribute(
                        "flowItemClientVariables",
                        flowItemClientVariable[0].FlowItemClientVariableId,
                        "VariableValue",
                        variableValue,
                        true
                    );
                } else {
                    deleteFlowItemClientVariable(flowItemClientVariable[0].FlowItemClientVariableId);
                }
            } else {
                deleteFlowItemClientVariable(flowItemClientVariable[0].FlowItemClientVariableId);
            }
        } else if (
            flowItemClientVariables.filter(x => x.VariableId == variableId && x.FlowItemId == flowItemId).length == 0
        ) {
            // export destination variables are tied to the export only so flowItemId and childFlowItemId will be the same
            newFlowItemClientVariable(variableId, selectedFlow, flowItemId, flowItemId, variableValue);
        }
        invalidateItemAndChildren(flowItemId);
    };

    determineVariableGroupValue = (defaultValue: string) => {
        const { companies, flow, selectedCompany, flowItems, flowRelations, flowMultiExportsByFlowItemId, flowItemId } =
            this.props;
        const company = companies.filter(x => x.CompanyID == selectedCompany)[0];

        let returnString = "";
        switch (defaultValue) {
            case "{CompanyName}":
                returnString = company.CompanyName;
                break;
            case "{AudienceName}": {
                let parentRelations = flowRelations.filter(x => x.ChildFlowItemId == flowItemId);

                if (parentRelations.length == 1 && flowMultiExportsByFlowItemId != null) {
                    const parentFlowItemId = parentRelations[0].ParentFlowItemId;
                    if (flowMultiExportsByFlowItemId[parentFlowItemId] != null) {
                        parentRelations = flowRelations.filter(x => x.ChildFlowItemId == parentFlowItemId);
                    }

                    if (parentRelations.length > 0) {
                        const parentId = parentRelations[0].ParentFlowItemId;

                        returnString = flowItems[parentId].FlowItemName;
                    }
                } else {
                    returnString = flow.FlowName;
                }

                break;
            }
            case "{FlowName}":
                returnString = flow.FlowName;
                break;
            default:
                break;
        }

        return returnString;
    };

    renderVariableText = (cellInfo: any) => {
        const {
            permissions: {
                item: { canEdit },
            },
            flow,
            isFreewheelOrMagniteDriverFile,
            variableGroups,
            flowItemId,
            flowItemClientVariables,
            enabledFeatures,
        } = this.props;

        const variableId = cellInfo.VariableId;
        const valueString = cellInfo.VariableValue ? cellInfo.VariableValue.ValueString : "";

        const flowItemCV = flowItemClientVariables.filter(
            x => x.FlowItemId == flowItemId && x.VariableId == variableId && x.ChildFlowItemId == this.props.flowItemId
        )[0];

        const varGroup = variableGroups.find(x => x.Id == cellInfo.VariableGroupId);
        if (variableId && varGroup && varGroup.DefaultValue != null && flowItemCV == null && !this.state.setDefaults) {
            let varValue = enabledFeatures.includes("variable-grouping-automation")
                ? this.determineVariableGroupValue(varGroup.DefaultValue)
                : "";

            if (valueString == "") {
                this.onDestVariableChange(
                    variableId,
                    {
                        Kind: "string",
                        ValueString: varValue,
                        ValueDate: null,
                        FieldId: null,
                    },
                    variableGroups
                );
                this.setState({ setDefaults: true });
            }
        }

        return (
            <TextField
                style={{ width: "98%" }}
                type="text"
                defaultValue={valueString || ""}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    maxLength: isFreewheelOrMagniteDriverFile ? 25 : 100,
                }}
                onBlur={e => {
                    const newValue = e.target.value;

                    const regex = /^$|^[a-z0-9_./\-\@ ]+$/i;
                    const pass = newValue.match(regex);
                    if (!pass) {
                        return;
                    }

                    this.onDestVariableChange(
                        variableId,
                        {
                            Kind: "string",
                            ValueString: newValue,
                            ValueDate: null,
                            FieldId: null,
                        },
                        variableGroups
                    );
                }}
            />
        );
    };

    render() {
        const { destinationVariables, destinationId } = this.props;
        const selectedDestinationVariables = destinationVariables.filter(x => x.PartnerAccessId == destinationId);
        if (!selectedDestinationVariables || selectedDestinationVariables.length == 0) {
            return null;
        }

        const theme = createTheme({
            overrides: {
                MuiTable: {
                    root: {
                        marginTop: "0px !important",
                    },
                },
                MuiTableCell: {
                    root: {
                        fontSize: "14px",
                    },
                },
            },
        });

        const variableTable = (
            <ThemeProvider theme={theme}>
                <MaterialTable
                    data={selectedDestinationVariables}
                    columns={this.getDestColumns()}
                    options={{
                        showTitle: false,
                        toolbar: false,
                        filtering: false,
                        grouping: false,
                        search: false,
                        sorting: false,
                        paging: false,
                        columnResizable: true,
                        tableLayout: "fixed",
                        headerStyle: { fontSize: 14, position: "sticky" },
                        rowStyle: {
                            fontSize: "14px",
                            padding: "2px",
                        },
                        maxBodyHeight: 400,
                    }}
                    style={{
                        margin: "20px 0px",
                    }}
                />
            </ThemeProvider>
        );

        return (
            <div>
                <div className="flow-edit-header">
                    <p>Destination Variables</p>
                </div>
                {variableTable}
            </div>
        );
    }
}
const makeGetDestinationVariables = () =>
    createSelector(
        (_, props) => props.flowItemId,
        (state, props) => state.flowExportObjects.destinationVariables[props.destinationId] || [],
        state => getFlowItemClientVariablesForSelectedFlow(state),
        (
            flowItemId: number,
            variables: Array<DestinationVariable>,
            flowItemClientVariables: Array<FlowItemClientVariableD>
        ): Array<DestinationVariableWithValue> => {
            const rows = [];
            for (const variable of variables) {
                const variableValue: { VariableValue: ?VariableValueType } = { VariableValue: null };

                const thisClientVariable = flowItemClientVariables.filter(
                    x => x.FlowItemId == flowItemId && x.VariableId == variable.VariableId
                );
                if (thisClientVariable.length == 1) {
                    variableValue.VariableValue = thisClientVariable[0].VariableValue;
                }

                const row: DestinationVariableWithValue = Object.assign({}, variable, variableValue);
                rows.push(row);
            }
            return rows;
        }
    );

const mapStateToProps: MapStateToProps<*, *, *> = () => {
    const getPermissionsItemFromProps = makeGetPermissionsItemFromProps();
    const getDestinationVariables = makeGetDestinationVariables();
    // const flowMultiExportsByFlowItemId = getFlowMultiExportsByFlowItemId(state);
    return (state, ownProps) => ({
        destinationVariables: getDestinationVariables(state, ownProps),
        flowItemClientVariables: getFlowItemClientVariablesForSelectedFlow(state),
        flowItems: state.flowItems.byId,
        flowMultiExportsByFlowItemId: getFlowMultiExportsByFlowItemId(state) || [],
        flowRelations: getFlowRelationsForSelectedFlow(state),
        permissions: getPermissionsItemFromProps(state, ownProps),
        selectedFlow: state.selected.flow,
        selectedCompany: state.session.companyId,
        enabledFeatures: state.session.enabledFeatures || [],
        flow: state.flows.byId[state.selected.flow],
        variableGroups: state.vars.variableGroupings || [],
        companies: state.session.companies,
    });
};

const mapDispatchToProps = dispatch => bindActionCreators(Object.assign({}, flowActions), dispatch);
const FlowExportVariableEditC = connect(mapStateToProps, mapDispatchToProps)(FlowExportVariableEdit);

export default FlowExportVariableEditC;
export { FlowExportVariableEdit };
