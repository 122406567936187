import React from "react";
// Redux + AC
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { MapStateToProps } from "react-redux";
import { arrayMoveImmutable } from "array-move";

import FlowItemMaxQtyToggle from "../Shared/FlowItemMaxQtyToggle";
import * as flowActions from "../../../../actions/flowActions";
import * as flowExportXandrDriverFieldActions from "../../../../actions/flowExportXandrDriverFieldActions";
import { getFlowRelationsForSelectedFlow } from "../../../../reducers/flowRelations";
import { getFlowItemClientVariablesForSelectedFlow } from "../../../../reducers/flowItemClientVariables";
import { getFlowExportsByFlowItemId } from "../../../../reducers/flowExports";
import { getFlowOutputsByFlowItemId } from "../../../../reducers/flowOutputs";
import { getFlowMultiExportsByFlowItemId } from "../../../../reducers/flowMultiExports";
import { getFlowRelationParentLabelsForSelectedFlow } from "../../../../reducers/flowRelationParentLabels";
import { makeGetPermissionsItemFromProps } from "../../../../reducers/flowItems";
import type { FlowExportTemplateFieldsByItemId } from "../../../../reducers/flowExportTemplateFields";
import type { FlowExportPinterestTemplateFieldsByItemId } from "../../../../reducers/flowExportPinterestTemplateFields";
import type { FlowExportTikTokTemplateFieldsByItemId } from "../../../../reducers/flowExportTikTokTemplateFields";
import type { FlowExportTradeDeskTemplateFieldsByItemId } from "../../../../reducers/flowExportTradeDeskTemplateFields";
import type { FlowOfferMergesByItemId } from "../../../../reducers/flowOfferMerges";
import { getFlowExportTemplateFieldsByFlowItemId } from "../../../../reducers/flowExportTemplateFields";
import { getFlowExportPinterestTemplateFieldsByFlowItemId } from "../../../../reducers/flowExportPinterestTemplateFields";
import { getFlowExportTikTokTemplateFieldsByFlowItemId } from "../../../../reducers/flowExportTikTokTemplateFields";
import { getFlowExportTradeDeskTemplateFieldsByFlowItemId } from "../../../../reducers/flowExportTradeDeskTemplateFields";
import { getFlowExportTaxonomyFileFieldsByFlowItemId } from "../../../../reducers/taxonomyLayout";
import type { FlowExportTaxonomyFileFieldsByItemId } from "../../../../reducers/taxonomyLayout";
import { getFlowSegmentSplitsForSelectedFlowItem } from "../../../../reducers/flowSegmentSplits";
import { getFlowOfferMergesByFlowItemId } from "../../../../reducers/flowOfferMerges";
import { getFlowMultiExportArray } from "../../../../reducers/flowMultiExports";
import { createSelector } from "reselect";
import FieldPicker from "../../../tree-field/FieldPicker";
import type {
    FlowRelation,
    FlowItem,
    FlowItemClientVariableD,
    FlowAndItemPermissions,
    UpdateAttribute,
    FlowOfferMerge,
    FlowSVDedupeD,
    FlowExport,
    FlowOutput,
    FlowRelationParentLabel,
    FlowMultiExport,
    FlowExportTemplateFields,
    FlowExportPinterestTemplateFields,
    FlowExportTikTokTemplateFields,
    FlowExportTradeDeskTemplateFields,
    FlowSegmentSplit,
    FlowSegmentSplitOffer,
} from "../../../../types/flowTypes";
import { IClientVariable, VariableValueType } from "../../../../types/stores/vars";
import { addCommas, renderFlowItemType } from "../../../../helpers/typedHelpers";
import type { Destination } from "../../../../types/types";
import { FlowExportFreewheelDriverFileFields } from "../../../../types/stores/freewheel";

import { makeGetFlowSVDedupe } from "../FlowSVDedupeEdit";
import FlowSVDedupeEdit from "../FlowSVDedupeEdit";
import OfferDedupeToggle from "../FlowOfferItems/OfferDedupeToggle";
import ExportTemplateFields from "./FlowExportTemplateFields";
import ExportPinterestTemplateFields from "./FlowExportPinterestTemplateFields";
import ExportTikTokTemplateFields from "./FlowExportTikTokTemplateFields";
import ExportTradeDeskTemplateFields from "./FlowExportTradeDeskTemplateFields";
import { makeGetFlowOfferMerge } from "../FlowOfferMergeEdit";
import FlowExportSplitToggle from "./FlowExportSplitToggle";
import FlowExportSplit from "./FlowExportSplit";
import FlowExportSeperateFilesToggle from "./FlowExportSeperateFilesToggle";
import FlowExportFreewheelDriverFileForm from "./FlowExportFreewheelDriverFileForm";
import FlowItemSnowflakeTables from "../Shared/FlowItemSnowflakeTables";
import XandrDriverFields from "./XandrDriverFields";

import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import NumericalTextField from "../../../material-components/Misc/NumericalTextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// material ui table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip, Icon } from "@material-ui/core";
import { getFlowSegmentSplitOffersForSelectedFlowItem } from "../../../../reducers/flowSegmentSplitOffers";
import CustomDedupeToggle from "../FlowOfferItems/CustomDedupeToggle";
import { getFlowExportFreewheelDriverFileFieldsByFlowItemId } from "../../../../reducers/freewheel";
import { DeploySettings } from "../../../../types/stores/companyTable";
import { IAdvertisersByCompany } from "../../../../types/stores/vars";

type Props = {
    //passed in
    destinationId: number,
    deliveryTypeId: number,
    splitOverwrite: boolean,
    flowItemId: number,
    flowExportId: number,
    isAdditionalTemplate: boolean,
    isPinterestTemplate: boolean,
    isTikTokTemplate: Boolean,
    isTradeDeskTemplate: boolean,
    isSnowflakeTable: Boolean,
    deploySetting: number,
    isMultiExport: Boolean,
    //redux
    preventOverrideDedupeExport: boolean,
    flowMultiExportsByFlowItemId: Array<number>,
    destinationOffers: Array<IClientVariable>,
    flowItems: { [number]: FlowItem },
    flowRelations: Array<FlowRelation>,
    flowItemClientVariables: Array<FlowItemClientVariableD>,
    layout: any,
    permissions: FlowAndItemPermissions,
    selectedFlow: number,
    selectedCompany: number,
    flowOfferMerges: Array<FlowOfferMerge>,
    flowExportOfferMerges: Array<FlowOfferMerge>,
    flowSVDedupe: ?FlowSVDedupeD,
    updateAttribute: UpdateAttribute,
    flowExport: ?FlowExport,
    flowOutput: ?FlowOutput,
    enabledFeatures: Array<string>,
    parentLabels: Array<FlowRelationParentLabel>,
    companies: Array<any>,
    variableGroups: Array<any>,
    flowExportTemplateFields: Array<FlowExportTemplateFields>,
    flowExportPinterestTemplateFields: Array<FlowExportPinterestTemplateFields>,
    flowExportTikTokTemplateFields: Array<FlowExportTikTokTemplateFields>,
    flowExportTradeDeskTemplateFields: Array<FlowExportTradeDeskTemplateFields>,
    flowExportFreewheelDriverFileFields: Array<FlowExportFreewheelDriverFileFields>,
    flowExportTaxonomyFileFields: Array<any>,
    flowSegmentSplits: Array<FlowSegmentSplit>,
    flowSegmentSplitOffers: Array<FlowSegmentSplitOffer>,
    destination: Destination,
    defaultDedupeKey: string,
    clientFieldObject: any,
    requestFlowExportDestinationOffers: (destinationId: number) => void,
    deleteFlowSVDedupe: (flowSVDedupeId: number) => void,
    newFlowItemClientVariable: (
        variableId: number,
        flowId: number,
        flowItemId: number,
        childFlowItemId: number,
        variableValue: VariableValueType
    ) => void,
    deleteFlowItemClientVariable: (id: number) => void,
    invalidateItemAndChildren: (flowItemId: number) => void,
    updateFlowOfferMerge: (flowOfferMergeId: number, priority: number) => void,
    newFlowOfferMerge: (flowItemId: number, parentFlowItemId: number) => void,
    newFlowSegmentSplitOverWrite: (flowItemId: number, parentFlowItemId: number, absoluteOrRelative: string) => void,
    newFlowExportTemplateFields: (flowItemId: number, parentFlowItemId: number) => void,
    deleteFlowExportTemplateFields: (id: number) => void,
    newFlowExportPinterestTemplateFields: (flowItemId: number, parentFlowItemId: number) => void,
    newFlowExportTikTokTemplateFields: (flowItemId: number, parentFlowItemId: number) => void,
    newFlowExportTradeDeskTemplateFields: (flowItemId: number, parentFlowItemId: number) => void,
    newFlowExportFreewheelDriverFileFields: (flowItemId: number, parentFlowItemId: number) => void,
    newFlowExportTaxonomyFileFields: (flowItemId: number, parentFlowItemId: number) => void,
    deleteFlowExportPinterestTemplateFields: (id: number) => void,
    deleteFlowExportTikTokTemplateFields: (id: number) => void,
    deleteFlowExportTradeDeskTemplateFields: (id: number) => void,
    deleteFlowExportFreewheelDriverFileFields: (id: number) => void,
    addFlowRelationParentLabel: (flowRelationId: number, parentLabel: string) => void,
    deleteFlowSegmentSplitOffer: (id: number) => void,
    deleteFlowSegmentSplit: (id: number) => void,
    flow: any,
    destinations: Array<IAdvertisersByCompany>,
};

type State = {
    tabKey: number,
    sortBy: string,
    sortDirection: string,
    isSetDedupeDefault: boolean,
    dedupeKey: string,
    sortByFields: string,
    splitExpandedId: number,
    previousTemplateID: number,
};

class FlowExportOfferEdit extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            tabKey: 1,
            sortBy: "ItemName",
            sortDirection: "ASC",
            isSetDedupeDefault: false,
            dedupeKey: "",
            sortByFields: "",
            splitExpandedId: 0,
            hasMounted: false,
            previousTemplateID: 0,
        };
    }

    componentDidMount() {
        const {
            destinationId,
            splitOverwrite,
            destinationOffers,
            flowSVDedupe,
            enabledFeatures,
            flowExport,
            updateAttribute,
            flowRelations,
            flowSegmentSplits,
            flowSegmentSplitOffers,
            newFlowSegmentSplitOverWrite,
            invalidateItemAndChildren,
            deleteFlowSegmentSplit,
            deleteFlowSegmentSplitOffer,
        } = this.props;

        if (!destinationOffers && destinationId) {
            this.props.requestFlowExportDestinationOffers(destinationId);
        }
        this.addMissingFlowOfferMerges();
        if (flowSVDedupe != null) {
            this.resetPriorities();
        }

        this.addMissingParentLabels();

        if (enabledFeatures.includes("flow-export-template-fields")) {
            this.updateTemplateFields();
        }

        this.updateTradeDeskTemplateFields();
        this.updatePinterestTemplateFields();
        this.updateTikTokTemplateFields();
        this.updateFreewheelDriverFileFields();
        this.updateTaxonomyFileFields();

        const hasDestinationSplitFeature = enabledFeatures.includes("flow-destination-level-splits");
        if (splitOverwrite) {
            if (flowExport != null) {
                const parentRelations = flowRelations.filter(x => x.ChildFlowItemId == flowExport.FlowItemId);

                if (!hasDestinationSplitFeature) {
                    if (flowExport.IsSeperateFiles) {
                        updateAttribute("flowExports", flowExport.FlowExportId, "IsSeperateFiles", false);
                    }
                    if (flowExport.IsSplitOn) {
                        updateAttribute("flowExports", flowExport.FlowExportId, "IsSplitOn", false);
                    }

                    for (const parent of parentRelations) {
                        const parentSegmentSplits = flowSegmentSplits.filter(
                            x => x.ParentFlowItemId == parent.ParentFlowItemId
                        );
                        if (parentSegmentSplits.length > 1) {
                            for (const split of parentSegmentSplits) {
                                deleteFlowSegmentSplit(split.SegmentSplitId);
                            }
                        }
                    }
                    for (const split of flowSegmentSplitOffers) {
                        deleteFlowSegmentSplitOffer(split.SegmentSplitOfferId);
                    }
                } else if (!flowExport.IsSeperateFiles) {
                    updateAttribute("flowExports", flowExport.FlowExportId, "IsSeperateFiles", true);
                }

                for (const parent of parentRelations) {
                    const parentSegmentSplits = flowSegmentSplits.filter(
                        x => x.ParentFlowItemId == parent.ParentFlowItemId
                    );
                    if (parentSegmentSplits.length == 0) {
                        newFlowSegmentSplitOverWrite(flowExport.FlowItemId, parent.ParentFlowItemId, "relative");
                        invalidateItemAndChildren(flowExport.FlowItemId);
                    }
                }
            }
        } else if (!hasDestinationSplitFeature) {
            if (flowExport.IsSeperateFiles) {
                updateAttribute("flowExports", flowExport.FlowExportId, "IsSeperateFiles", false);
            }
            if (flowExport.IsSplitOn) {
                updateAttribute("flowExports", flowExport.FlowExportId, "IsSplitOn", false);
            }
            for (const split of flowSegmentSplits) {
                deleteFlowSegmentSplit(split.SegmentSplitId);
            }
            for (const split of flowSegmentSplitOffers) {
                deleteFlowSegmentSplitOffer(split.SegmentSplitOfferId);
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {
            destinationId,
            splitOverwrite,
            destinationOffers,
            flowSVDedupe,
            flowRelations,
            layout,
            enabledFeatures,
            flowExport,
            updateAttribute,
            flowSegmentSplits,
            newFlowSegmentSplitOverWrite,
            invalidateItemAndChildren,
            flowOfferMerges,
        } = this.props;

        if (destinationId && prevProps.destinationId != destinationId && !destinationOffers) {
            this.props.requestFlowExportDestinationOffers(destinationId);
        }

        if (
            prevProps.flowRelations != flowRelations ||
            prevProps.destinationOffers != destinationOffers ||
            prevProps.layout != layout
        ) {
            this.addMissingFlowOfferMerges();
            this.addMissingParentLabels();
        }

        if (prevProps.layout != layout && this.state.hasMounted) {
            // this.addMissingFlowOfferMerges();
        }

        if (flowSVDedupe != null) {
            this.resetPriorities();
        }

        if (destinationId != prevProps.destinationId && enabledFeatures.includes("flow-export-template-fields")) {
            this.updateTemplateFields();
        }

        if (flowExport.DeliveryTypeId != null && flowExport.DeliveryTypeId != this.state.previousTemplateID) {
            this.updateTradeDeskTemplateFields();
            this.setState({ previousTemplateID: flowExport.DeliveryTypeId });
        }

        if (destinationId != prevProps.destinationId) {
            this.updatePinterestTemplateFields();
            this.updateTikTokTemplateFields();
            this.updateTradeDeskTemplateFields();
            this.updateFreewheelDriverFileFields();
            this.updateTaxonomyFileFields();
        }

        if (flowOfferMerges != prevProps.flowOfferMerges) {
            this.updateFreewheelDriverFileFields();
        }

        const hasDestinationSplitFeature = enabledFeatures.includes("flow-destination-level-splits");
        if (splitOverwrite != prevProps.splitOverwrite) {
            if (flowExport != null) {
                if (splitOverwrite) {
                    if (hasDestinationSplitFeature && !flowExport.IsSeperateFiles) {
                        updateAttribute("flowExports", flowExport.FlowExportId, "IsSeperateFiles", true);
                    }
                    const parentRelations = flowRelations.filter(x => x.ChildFlowItemId == flowExport.FlowItemId);

                    for (const parent of parentRelations) {
                        const parentSegmentSplits = flowSegmentSplits.filter(
                            x => x.ParentFlowItemId == parent.ParentFlowItemId
                        );
                        if (parentSegmentSplits.length == 0) {
                            newFlowSegmentSplitOverWrite(flowExport.FlowItemId, parent.ParentFlowItemId, "relative");
                            invalidateItemAndChildren(flowExport.FlowItemId);
                        }
                    }
                }
            }
        }

        if (flowExport.IsSplitOn != prevProps.flowExport.IsSplitOn && !flowExport.IsSplitOn) {
            if (splitOverwrite) {
                if (hasDestinationSplitFeature && !flowExport.IsSeperateFiles) {
                    updateAttribute("flowExports", flowExport.FlowExportId, "IsSeperateFiles", true);
                }
                const parentRelations = flowRelations.filter(x => x.ChildFlowItemId == flowExport.FlowItemId);

                for (const parent of parentRelations) {
                    const parentSegmentSplits = flowSegmentSplits.filter(
                        x => x.ParentFlowItemId == parent.ParentFlowItemId
                    );
                    if (parentSegmentSplits.length == 0) {
                        newFlowSegmentSplitOverWrite(flowExport.FlowItemId, parent.ParentFlowItemId, "relative");
                        invalidateItemAndChildren(flowExport.FlowItemId);
                    }
                }
            }
        }
    }

    updateTemplateFields = () => {
        const {
            flowExportTemplateFields,
            isAdditionalTemplate,
            flowOfferMerges,
            permissions: {
                item: { canEdit },
            },
            deleteFlowExportTemplateFields,
            newFlowExportTemplateFields,
        } = this.props;

        if (!canEdit) {
            return;
        }

        if (!isAdditionalTemplate) {
            for (const template of flowExportTemplateFields) {
                deleteFlowExportTemplateFields(template.FlowExportTemplateId);
            }
        } else {
            for (const offer of flowOfferMerges) {
                const exportTemplate = flowExportTemplateFields.find(
                    x => x.FlowItemId == offer.FlowItemId && x.ParentFlowItemId == offer.ParentFlowItemId
                );
                if (!exportTemplate) {
                    newFlowExportTemplateFields(offer.FlowItemId, offer.ParentFlowItemId);
                }
            }
        }
    };

    updatePinterestTemplateFields = () => {
        const {
            flowExportPinterestTemplateFields,
            isPinterestTemplate,
            flowOfferMerges,
            permissions: {
                item: { canEdit },
            },
            deleteFlowExportPinterestTemplateFields,
            newFlowExportPinterestTemplateFields,
            destination,
        } = this.props;

        if (!canEdit) {
            return;
        }

        if (isPinterestTemplate) {
            for (const offer of flowOfferMerges) {
                const exportTemplate = flowExportPinterestTemplateFields.find(
                    x => x.FlowItemId == offer.FlowItemId && x.ParentFlowItemId == offer.ParentFlowItemId
                );
                if (!exportTemplate) {
                    newFlowExportPinterestTemplateFields(offer.FlowItemId, offer.ParentFlowItemId);
                }
            }
        } else if (destination) {
            for (const template of flowExportPinterestTemplateFields) {
                deleteFlowExportPinterestTemplateFields(template.FlowExportTemplateId);
            }
        }
    };

    updateTikTokTemplateFields = () => {
        const {
            flowExportTikTokTemplateFields,
            isTikTokTemplate,
            flowOfferMerges,
            permissions: {
                item: { canEdit },
            },
            deleteFlowExportTikTokTemplateFields,
            newFlowExportTikTokTemplateFields,
            destination,
        } = this.props;

        if (!canEdit) {
            return;
        }

        if (isTikTokTemplate) {
            for (const offer of flowOfferMerges) {
                const exportTemplate = flowExportTikTokTemplateFields.find(
                    x => x.FlowItemId == offer.FlowItemId && x.ParentFlowItemId == offer.ParentFlowItemId
                );
                if (!exportTemplate) {
                    newFlowExportTikTokTemplateFields(offer.FlowItemId, offer.ParentFlowItemId);
                }
            }
        } else if (destination) {
            for (const template of flowExportTikTokTemplateFields) {
                deleteFlowExportTikTokTemplateFields(template.FlowExportTemplateId);
            }
        }
    };

    updateTaxonomyFileFields = () => {
        const {
            flowOfferMerges,
            flowExportTaxonomyFileFields,
            permissions: {
                item: { canEdit },
            },
            layout,
            newFlowExportTaxonomyFileFields,
        } = this.props;

        if (!canEdit) {
            return;
        }

        if (layout != null && layout.Layout != null && layout.Layout.TaxonomyLayout && layout.LayoutTaxonomyObjects) {
            for (const offer of flowOfferMerges) {
                const taxonomyField = flowExportTaxonomyFileFields.find(
                    x => x.FlowItemId == offer.FlowItemId && x.ParentFlowItemId == offer.ParentFlowItemId
                );

                let taxonomyValues = {};

                layout.LayoutTaxonomyObjects.forEach(x => {
                    taxonomyValues[x.ExportName] = "";
                });

                if (!taxonomyField) {
                    newFlowExportTaxonomyFileFields(offer.FlowItemId, offer.ParentFlowItemId, taxonomyValues);
                }
            }
        }
    };

    updateFreewheelDriverFileFields = () => {
        const {
            flowExportFreewheelDriverFileFields,
            deploySetting,
            flowOfferMerges,
            permissions: {
                item: { canEdit },
            },
            deleteFlowExportFreewheelDriverFileFields,
            newFlowExportFreewheelDriverFileFields,
            destination,
        } = this.props;

        if (!canEdit) {
            return;
        }

        if (
            deploySetting == DeploySettings.DeployFreewheelDriverFile ||
            deploySetting == DeploySettings.DeployMagniteDriverFile
        ) {
            for (const offer of flowOfferMerges) {
                const exportTemplate = flowExportFreewheelDriverFileFields.find(
                    x => x.FlowItemId == offer.FlowItemId && x.ParentFlowItemId == offer.ParentFlowItemId
                );
                if (!exportTemplate) {
                    newFlowExportFreewheelDriverFileFields(offer.FlowItemId, offer.ParentFlowItemId);
                }
            }
        } else if (destination) {
            for (const template of flowExportFreewheelDriverFileFields) {
                deleteFlowExportFreewheelDriverFileFields(template.FlowExportFreewheelDriverFileFieldId);
            }
        }
    };

    updateTradeDeskTemplateFields = () => {
        const {
            flowExportTradeDeskTemplateFields,
            flowExport,
            enabledFeatures,
            isTradeDeskTemplate,
            flowOfferMerges,
            permissions: {
                item: { canEdit },
            },
            deleteFlowExportTradeDeskTemplateFields,
            newFlowExportTradeDeskTemplateFields,
            destination,
        } = this.props;

        if (!canEdit) {
            return;
        }

        if (isTradeDeskTemplate) {
            const hasRateFeature = enabledFeatures.includes("display-field-rates");
            for (const offer of flowOfferMerges) {
                const exportTemplate = flowExportTradeDeskTemplateFields.find(
                    x => x.FlowItemId == offer.FlowItemId && x.ParentFlowItemId == offer.ParentFlowItemId
                );
                if (
                    (flowExport.DeliveryTypeId != null && hasRateFeature && !exportTemplate) ||
                    (!hasRateFeature && !exportTemplate)
                ) {
                    // need to add the logic here to not update the template?
                    newFlowExportTradeDeskTemplateFields(offer.FlowItemId, offer.ParentFlowItemId);
                }
            }
        } else if (destination) {
            for (const template of flowExportTradeDeskTemplateFields) {
                deleteFlowExportTradeDeskTemplateFields(template.FlowExportTemplateId);
            }
        }
    };

    resetPriorities = () => {
        let sortedOfferMerges = this.getSortedOfferMerges();

        const idsToUpdate = [];

        let maxPri = Math.max(...sortedOfferMerges.map(x => x.FlowOfferPriority));
        // Set New items to bottom priority
        for (const flowOfferMerge of sortedOfferMerges) {
            if (flowOfferMerge.FlowOfferPriority <= 0) {
                flowOfferMerge.FlowOfferPriority = ++maxPri;
                idsToUpdate.push(flowOfferMerge.FlowOfferMergeId);
            }
        }
        //sort againg because priorities may have been updated.
        sortedOfferMerges = sortedOfferMerges.sort((a, b) => a.FlowOfferPriority - b.FlowOfferPriority);
        let newPri = 1;
        for (const flowOfferMerge of sortedOfferMerges) {
            if (idsToUpdate.includes(flowOfferMerge.FlowOfferMergeId) || flowOfferMerge.FlowOfferPriority != newPri) {
                this.props.updateFlowOfferMerge(flowOfferMerge.FlowOfferMergeId, newPri);
            }
            newPri++;
        }
    };

    getSortedOfferMerges = () =>
        this.props.flowOutput
            ? this.props.flowOfferMerges.sort((a, b) => a.FlowOfferPriority - b.FlowOfferPriority)
            : this.props.flowOfferMerges.sort((a, b) => a.FlowOfferPriority - b.FlowOfferPriority);

    addMissingFlowOfferMerges = () => {
        const {
            flowItemId,
            flowRelations,
            flowOfferMerges,
            flowExport,
            isAdditionalTemplate,
            isPinterestTemplate,
            isTikTokTemplate,
            isTradeDeskTemplate,
            enabledFeatures,
            layout,
            flowMultiExportsByFlowItemId,
            flowExportTaxonomyFileFields,
            flowExportTradeDeskTemplateFields,
        } = this.props;
        if (flowExport != null) {
            let parentRelations = flowRelations.filter(x => x.ChildFlowItemId == flowItemId);

            if (parentRelations.length == 1 && flowMultiExportsByFlowItemId != null) {
                const parentFlowItemId = parentRelations[0].ParentFlowItemId;
                if (flowMultiExportsByFlowItemId[parentFlowItemId] != null) {
                    parentRelations = flowRelations.filter(x => x.ChildFlowItemId == parentFlowItemId);
                }
            }

            for (const parent of parentRelations) {
                const offerMerge = flowOfferMerges.find(x => x.ParentFlowItemId == parent.ParentFlowItemId);
                if (!offerMerge && parent.ParentFlowItemId != 0) {
                    //0 means this was ran without a parent assigned
                    //offer merges are usually created when a relation is added to a flow offer item
                    //now we need this for export items but only when they pick a destination layout
                    //and it has destination offers

                    // if (flowMultiExports != null && flowMultiExports.FlowItemId == parent.ParentFlowItemId) {
                    // } else {
                    this.props.newFlowOfferMerge(flowItemId, parent.ParentFlowItemId);
                    // }

                    if (isAdditionalTemplate && enabledFeatures.includes("flow-export-template-fields")) {
                        this.props.newFlowExportTemplateFields(flowItemId, parent.ParentFlowItemId);
                    }

                    if (isPinterestTemplate) {
                        this.props.newFlowExportPinterestTemplateFields(flowItemId, parent.ParentFlowItemId);
                    }

                    if (isTikTokTemplate) {
                        this.props.newFlowExportTikTokTemplateFields(flowItemId, parent.ParentFlowItemId);
                    }

                    if (layout.LayoutTaxonomyObjects && layout.LayoutTaxonomyObjects.length > 0) {
                        const taxonomyField = flowExportTaxonomyFileFields.find(
                            x => x.FlowItemId == flowItemId && x.ParentFlowItemId == parent.ParentFlowItemId
                        );

                        let taxonomyValues = {};

                        layout.LayoutTaxonomyObjects.forEach(x => {
                            taxonomyValues[x.ExportName] = "";
                        });

                        if (!taxonomyField) {
                            this.props.newFlowExportTaxonomyFileFields(
                                flowItemId,
                                parent.ParentFlowItemId,
                                taxonomyValues
                            );
                        }
                    }

                    if (isTradeDeskTemplate) {
                        const hasRateFeature = enabledFeatures.includes("display-field-rates");
                        if ((flowExport.DeliveryTypeId != null && hasRateFeature) || !hasRateFeature) {
                            // does a record exist? If so, then don't add another one.
                            const exportTemplate = flowExportTradeDeskTemplateFields.find(
                                x => x.FlowItemId == flowItemId && x.ParentFlowItemId == parent.ParentFlowItemId
                            );
                            if (exportTemplate && exportTemplate.length == 0) {
                                this.props.newFlowExportTradeDeskTemplateFields(flowItemId, parent.ParentFlowItemId);
                            }
                        }
                    }
                }
            }
        }
        this.setState({ hasMounted: true });
    };

    getRequiredOfferCodes = () => {
        const { destinationOffers, layout } = this.props;

        if (
            !destinationOffers ||
            destinationOffers.length <= 0 ||
            !layout ||
            !layout.Layout ||
            !layout.Layout.DestinationLayout ||
            !layout.LayoutObjects
        ) {
            return [];
        }
        const offersInLayout = layout.LayoutObjects.filter(x => x.ObjectType == 4).map(x => x.ObjectId);
        return destinationOffers.filter(x => offersInLayout.includes(x.Id));
    };

    renderOutputQuantity = (value, isDedupe = false) => {
        const { flowItemId, flowItems, flowExport } = this.props;
        const flowItem = flowItems[flowItemId];

        if (!flowItem || (!flowItem.HasResultTable && value == 0) || !flowExport) {
            return "N/A";
        }
        if (!isDedupe && !flowExport.MeetsMinimumThreshold) {
            return "Below Threshold";
        }
        if (!isDedupe && flowExport.IsApprovalRequired && !flowExport.IsCountApproved) {
            return "Approval Required";
        }

        return addCommas(value);
    };

    onMaxQuantityChange = (val, Id) => {
        const { flowOfferMerges, updateAttribute } = this.props;
        if (!flowOfferMerges.find(x => x.FlowOfferMergeId == Id)) {
            return;
        }

        let newValue = parseInt(val) || val;
        updateAttribute("flowOfferMerges", Id, "MaxQty", newValue, true);
    };

    onMaxQuantityChangeForDrop = (val, Id) => {
        const { flowOfferMerges, updateAttribute } = this.props;

        if (!flowOfferMerges.find(x => x.FlowOfferMergeId == Id)) {
            return;
        }

        if (val == 1) {
            updateAttribute("flowOfferMerges", Id, "MaxQty", 1, true);
        } else {
            updateAttribute("flowOfferMerges", Id, "MaxQty", 0, true);
        }
    };

    renderMaxQuantity = (ParentFlowItemId, value) => {
        const {
            permissions: {
                item: { canEdit },
            },
            flow,
            flowExport,
            updateAttribute,
        } = this.props;
        const item = this.props.flowOfferMerges.find(x => x.ParentFlowItemId == ParentFlowItemId);
        if (!item) {
            return null;
        }

        if (
            !flowExport.IsMaxQtyOn &&
            item.MaxQty != 0 &&
            this.props.flowOfferMerges.find(x => x.FlowOfferMergeId == item.FlowOfferMergeId)
        ) {
            updateAttribute("flowOfferMerges", item.FlowOfferMergeId, "MaxQty", 0, true);
        }
        const isShowNumbers = value > 0 || value == "";

        const maxQtySection = isShowNumbers ? (
            <NumericalTextField
                key={"maxQtyKey_" + item.FlowOfferMergeId}
                id={"maxQtyId_" + item.FlowOfferMergeId}
                disabled={!canEdit || (flow && flow.IsLocked)}
                className="material-form-control"
                size={"small"}
                maxLength={10}
                value={value || ""}
                onBlur={e => this.onMaxQuantityChange(e.target.value, item.FlowOfferMergeId)}
            />
        ) : (
            <Select
                style={{ fontSize: "14px", width: "125px", marginTop: "10px" }}
                value={0}
                onChange={e => this.onMaxQuantityChangeForDrop(e.target.value, item.FlowOfferMergeId)}
                displayEmpty
            >
                <MenuItem key={`test1`} value={0}>
                    Select All
                </MenuItem>
                <MenuItem key={`test2`} value={1}>
                    Set Max Value
                </MenuItem>
            </Select>
        );
        return maxQtySection;
    };

    renderEditableString = (variableId, flowItemId, editableIdentifier, groupId, variableScope) => {
        const {
            permissions: {
                item: { canEdit },
            },
            flowItemClientVariables,
            flow,
            destination,
            destinationOffers,
            enabledFeatures,
            variableGroups,
            flowItems,
        } = this.props;

        const flowItemCV = flowItemClientVariables.filter(
            x => x.FlowItemId == flowItemId && x.VariableId == variableId && x.ChildFlowItemId == this.props.flowItemId
        )[0];

        // #EMS 1301 adding autocomplete to Segment Codes with parent filter name
        let valueString = flowItemCV ? flowItemCV.VariableValue.ValueString : "";
        if (variableScope == "DestinationOffer" && valueString == "") {
            valueString = flowItemCV ? flowItems[flowItemCV.FlowItemId].FlowItemName : "";
            const newValueObject: VariableValueType = {
                Kind: "string",
                ValueString: valueString,
                ValueDate: null,
                FieldId: null,
            };

            this.onVariableChangeString(flowItemCV, variableId, flowItemId, this.props.flowItemId, newValueObject);
        }
        const offer = destinationOffers.find(x => x.Id == variableId);
        const varGroup = variableGroups.find(x => x.Id == groupId);
        let isReadOnly = false;
        let helperText = null;
        if (
            destination &&
            (destination.DeploySetting == DeploySettings.DeployFreewheelDriverFile ||
                destination.DeploySetting == DeploySettings.DeployMagniteDriverFile) &&
            offer.VariableName.replace(" ", "").toUpperCase() == "SEGMENTID"
        ) {
            isReadOnly = true;

            const expectedValue = `${flow.FlowId}${flowItemId}`;
            if (flow.FlowId <= 0 || flowItemId <= 0) {
                helperText = "* Please save your flow to obtain the Segment ID";
            } else if (valueString != expectedValue) {
                const newValueObject: VariableValueType = {
                    Kind: "string",
                    ValueString: expectedValue,
                    ValueDate: null,
                    FieldId: null,
                };

                this.onVariableChangeString(flowItemCV, variableId, flowItemId, this.props.flowItemId, newValueObject);
            }
        }

        if (offer && varGroup && varGroup.DefaultValue != null && flowItemCV == null) {
            let varValue = enabledFeatures.includes("variable-grouping-automation")
                ? this.determineVariableGroupValue(varGroup.DefaultValue, flowItemId)
                : "";
            if (valueString == "") {
                const newValueObject: VariableValueType = {
                    Kind: "string",
                    ValueString: varValue,
                    ValueDate: null,
                    FieldId: null,
                };

                this.onVariableChangeString(flowItemCV, variableId, flowItemId, this.props.flowItemId, newValueObject);
            }
        }

        const editableString = (
            <TextField
                style={{
                    fontSize: "16px !important",
                    width: "95%",
                    marginLeft: "15px",
                    top: "-5px",
                    position: "relative",
                    margin: "6px",
                }}
                key={variableId + "_" + flowItemId}
                type="text"
                disabled={isReadOnly || !canEdit || (flow && flow.IsLocked)}
                value={valueString}
                onKeyPress={e => {
                    this.handleEnterPress(e);
                }}
                inputProps={{
                    style: { fontSize: "16px" },
                    maxLength: 400,
                }}
                helperText={helperText}
                id={editableIdentifier}
                onChange={e => {
                    const newValue = e.target.value;

                    let regex = /^$|^[a-z0-9%_./\- ]+$/i;
                    if (enabledFeatures.includes("export-destination-offer-variables")) {
                        regex = /^$|^[a-z0-9%_./\-{}: ]+$/i;
                    }
                    const pass = newValue.match(regex);
                    if (!pass) {
                        return;
                    }

                    const newValueObject: VariableValueType = {
                        Kind: "string",
                        ValueString: newValue,
                        ValueDate: null,
                        FieldId: null,
                    };

                    this.onVariableChangeString(
                        flowItemCV,
                        variableId,
                        flowItemId,
                        this.props.flowItemId,
                        newValueObject
                    );
                }}
            />
        );

        return editableString;
    };

    renderParentLabelColumn = (FlowRelationParentLabelId, value) => {
        const {
            permissions: {
                item: { canEdit },
            },
            flow,
        } = this.props;

        const regex = new RegExp(/[^a-zA-Z0-9_ ]/g);
        const hasError = regex.test(value);

        const parentLabelColumn = (
            <TextField
                style={{
                    fontSize: "16px !important",
                    width: "95%",
                    marginLeft: "15px",
                    top: "-5px",
                    position: "relative",
                    margin: "6px",
                }}
                key={"parent-label-" + FlowRelationParentLabelId}
                type="text"
                value={value}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    style: { fontSize: "16px" },
                }}
                error={hasError}
                label={hasError ? "Please remove invalid characters from this Output Column Name." : ""}
                onChange={e => {
                    const regex = /[^a-zA-Z0-9_ ]/g;
                    const newValue = e.target.value.replace(regex, "");

                    this.props.updateAttribute(
                        "flowRelationParentLabels",
                        FlowRelationParentLabelId,
                        "ParentLabel",
                        newValue,
                        e.target.value,
                        true
                    );
                    this.props.invalidateItemAndChildren(this.props.flowItemId);
                }}
            />
        );

        return parentLabelColumn;
    };

    getEditableFieldsColumns = (arrayToPopulate, table, col) => {
        if (table) {
            const numRows = table.children.length || 0;
            let row = 0;

            if (col < 0) {
                return;
            }

            for (row = 0; row < numRows; ++row) {
                if (table.children[row].children[0].children[col].children.length > 0) {
                    if (
                        table.children[row].children[0].children[col].children[0].className ==
                        "form-control editable-string-td"
                    ) {
                        arrayToPopulate.push(table.children[row].children[0].children[col].children[0]);
                    }
                }
            }
        }
    };

    handleEnterPress = e => {
        let editableStringArray = [];
        const table = document.querySelector("#export-offer-code-tables");
        const tableBody = table && table instanceof HTMLElement ? table.children[0].children[1] : null;
        if (tableBody && tableBody.className == "rt-tbody") {
            for (let i = 0; i < tableBody.children[0].children[0].children.length; ++i) {
                this.getEditableFieldsColumns(editableStringArray, tableBody, i);
            }

            // Set needed variables
            const enterKey = "Enter";
            const useShiftKey = e.shiftKey;
            const useEnterKey = e.key == enterKey;
            const identifier = e.target.id;
            let nextChild = null;

            if (useEnterKey && !useShiftKey) {
                nextChild =
                    editableStringArray[
                        editableStringArray.findIndex(el => {
                            if (el) {
                                return el.id == identifier;
                            }
                        }) + 1
                    ];

                if (nextChild && nextChild instanceof HTMLInputElement) {
                    nextChild.focus();
                    nextChild.select();
                }
            } else if (useEnterKey && useShiftKey) {
                nextChild =
                    editableStringArray[
                        editableStringArray.findIndex(el => {
                            if (el) {
                                return el.id == identifier;
                            }
                        }) - 1
                    ];

                if (nextChild && nextChild instanceof HTMLInputElement) {
                    nextChild.focus();
                    nextChild.select();
                }
            }
        }
    };

    renderPrioritySelect = parentFlowItemId => {
        const {
            flowOfferMerges,
            permissions: {
                item: { canEdit },
            },
            flow,
        } = this.props;
        const item = flowOfferMerges.find(x => x.ParentFlowItemId == parentFlowItemId);
        if (!item) {
            return null;
        }
        return (
            <NumericalTextField
                key={"priority_" + item.FlowOfferMergeId}
                className="material-form-control"
                disabled={!canEdit || (flow && flow.IsLocked)}
                size="small"
                maxLength={3}
                max={flowOfferMerges.length}
                value={item.FlowOfferPriority}
                onBlur={e => this.onPriorityChange(e.target.value, item.FlowOfferMergeId)}
            />
        );
    };

    onChangeCallback = () => {
        const { flowExport, updateAttribute } = this.props;
        if (flowExport != null) {
            this.setState({ tabKey: 1 });
            if (this.state.isSetDedupeDefault) {
                updateAttribute("flowExports", flowExport.FlowExportId, "IsDedupeSaved", true);
                this.setState({ isSetDedupeDefault: false });
            }
        }
    };

    onPriorityChange = (val, Id) => {
        const { flowItemId } = this.props;
        const sortedOfferMerges = this.getSortedOfferMerges();

        const changedOffer = sortedOfferMerges.find(x => x.FlowOfferMergeId == Id);
        if (!changedOffer) {
            return;
        }
        let intNewPri = parseInt(val);

        if (intNewPri < 1) {
            intNewPri = 1;
        }

        const oldIndex = sortedOfferMerges.indexOf(changedOffer);
        const newIndex = intNewPri - 1;
        let updatedMerges = arrayMoveImmutable(sortedOfferMerges, oldIndex, newIndex);
        updatedMerges.forEach((value, index) => {
            const newPri = index + 1;
            const idToUpdate = updatedMerges[index]["FlowOfferMergeId"];
            this.props.updateFlowOfferMerge(idToUpdate, newPri);
        });

        this.props.invalidateItemAndChildren(flowItemId);
    };

    determineVariableGroupValue = (defaultValue: string, flowItemId: number) => {
        const { flowExport, companies, flow, flowItems, selectedCompany, flowRelations, flowMultiExportsByFlowItemId } =
            this.props;
        const company = companies.filter(x => x.CompanyID == selectedCompany)[0];
        let returnString = "";
        switch (defaultValue) {
            case "{CompanyName}":
                returnString = company.CompanyName;
                break;
            case "{AudienceName}": {
                let parentRelations = flowRelations.filter(x => x.ChildFlowItemId == flowExport.FlowItemId);

                //Export parent is a MultiDestinationNode
                if (
                    parentRelations.length == 1 &&
                    flowMultiExportsByFlowItemId[parentRelations[0].ParentFlowItemId] != null
                ) {
                    parentRelations = flowRelations.filter(
                        x => x.ChildFlowItemId == parentRelations[0].ParentFlowItemId
                    );

                    //MultiExport has one parent, could be simple flow
                    if (parentRelations.length == 1) {
                        const parentFlowItemId = parentRelations[0].ParentFlowItemId;
                        if (flowMultiExportsByFlowItemId[parentFlowItemId] != null) {
                            parentRelations = flowRelations.filter(x => x.ChildFlowItemId == parentFlowItemId);
                        }

                        if (parentRelations.length > 0) {
                            const parentId = parentRelations[0].ParentFlowItemId;
                            returnString = flowItems[parentId].FlowItemName;
                        }
                    } else if (parentRelations.length > 1) {
                        //MultiExport has multiple parents
                        returnString = flowItems[flowItemId].FlowItemName;
                    }
                } else if (parentRelations.length > 1 || parentRelations.length == 1) {
                    returnString = flowItems[flowItemId].FlowItemName;
                } else {
                    returnString = flow.FlowName;
                }

                break;
            }
            case "{FlowName}":
                returnString = flow.FlowName;
                break;
            default:
                break;
        }

        return returnString;
    };

    onVariableChangeString = (
        flowItemClientVariable: ?FlowItemClientVariableD,
        variableId: number,
        flowItemId: number,
        childFlowItemId: number,
        variableValue: VariableValueType
    ) => {
        const {
            selectedFlow,
            updateAttribute,
            newFlowItemClientVariable,
            deleteFlowItemClientVariable,
            flowItemClientVariables,
        } = this.props;
        if (flowItemClientVariable) {
            if (variableValue) {
                if (variableValue.ValueString != "") {
                    updateAttribute(
                        "flowItemClientVariables",
                        flowItemClientVariable.FlowItemClientVariableId,
                        "VariableValue",
                        variableValue,
                        true
                    );
                } else {
                    deleteFlowItemClientVariable(flowItemClientVariable.FlowItemClientVariableId);
                }
            } else {
                deleteFlowItemClientVariable(flowItemClientVariable.FlowItemClientVariableId);
            }
        } else if (
            flowItemClientVariables.filter(
                x => x.VariableId == variableId && x.FlowItemId == flowItemId && x.ChildFlowItemId == childFlowItemId
            ).length == 0
        ) {
            newFlowItemClientVariable(variableId, selectedFlow, flowItemId, childFlowItemId, variableValue);
        } else {
            newFlowItemClientVariable(variableId, selectedFlow, flowItemId, childFlowItemId, variableValue);
        }

        this.props.invalidateItemAndChildren(this.props.flowItemId);
    };

    renderOfferTable = () => {
        const { flowItems, flowRelations, parentLabels, flowExport, flowOutput, flowMultiExportsByFlowItemId } =
            this.props;
        let table = [];

        if (flowExport) {
            let parentRelations = flowRelations.filter(x => x.ChildFlowItemId == flowExport.FlowItemId);

            if (parentRelations.length == 1 && flowMultiExportsByFlowItemId) {
                const parentFlowItemId = parentRelations[0].ParentFlowItemId;
                if (flowMultiExportsByFlowItemId[parentFlowItemId]) {
                    parentRelations = flowRelations.filter(x => x.ChildFlowItemId == parentFlowItemId);
                }
            }

            if (flowOutput) {
                parentRelations = flowRelations.filter(x => x.ChildFlowItemId == flowOutput.FlowItemId);
            }

            const parentLabelsMap = new Map(parentLabels.map(label => [label.FlowRelationId, label]));
            const sortedOfferMerges = this.getSortedOfferMerges();

            for (const offerMerge of sortedOfferMerges) {
                const parentRelation = parentRelations.find(x => x.ParentFlowItemId == offerMerge.ParentFlowItemId);
                if (parentRelation) {
                    let parentLabel = parentLabelsMap.get(parentRelation.FlowRelationId);

                    if (!parentLabel) {
                        // Handle multi-export node
                        const parentLabelRelation = flowRelations.find(
                            x =>
                                x.ParentFlowItemId === parentRelation.ChildFlowItemId &&
                                x.ChildFlowItemId === offerMerge.FlowItemId &&
                                parentLabelsMap.has(x.FlowRelationId)
                        );
                        parentLabel = parentLabelsMap.get(parentLabelRelation?.FlowRelationId);
                    }

                    const parentFlowItem: ?FlowItem = flowItems[parentRelation.ParentFlowItemId];
                    if (parentFlowItem) {
                        let line = {
                            ParentFlowItemId: parentFlowItem.FlowItemId,
                            Quantity: parentFlowItem.FlowItemQty ?? parseInt(parentFlowItem.FlowItemQty),
                            ItemName: "",
                            DupesQty: offerMerge.DupesQty,
                            FinalQty: offerMerge.FinalQty,
                            MaxQty: offerMerge.MaxQty,
                            OutputQty: offerMerge.OutputQty,
                            ParentLabel: parentLabel ? parentLabel.ParentLabel : "",
                            FlowRelationParentLabelId: parentLabel ? parentLabel.FlowRelationParentLabelId : null,
                        };

                        if (parentFlowItem.FlowItemType != "empty") {
                            const parentFlowItemType = renderFlowItemType(parentFlowItem.FlowItemType);
                            line.ItemName = parentFlowItem.FlowItemName + " - " + parentFlowItemType;
                        } else {
                            line.ItemName = parentFlowItem.FlowItemName;

                            const emptyParentRelations = flowRelations
                                .filter(x => x.ChildFlowItemId == parentFlowItem.FlowItemId && x.ParentFlowItemId != 0)
                                .map(x => x.ParentFlowItemId);

                            if (emptyParentRelations.length > 0) {
                                const emptyParentFlowItemId = emptyParentRelations[0];
                                const emptyParentFlowItem: FlowItem = flowItems[emptyParentFlowItemId];
                                const emptyParentFlowItemType = renderFlowItemType(emptyParentFlowItem.FlowItemType);
                                line.ItemName +=
                                    " - " + emptyParentFlowItem.FlowItemName + " - " + emptyParentFlowItemType;
                            }
                        }

                        table.push(line);
                    }
                }
            }
        }

        return table;
    };

    addMissingParentLabels = () => {
        const { flowRelations, parentLabels, flowItemId, flowItems, layout, flowMultiExportsByFlowItemId } = this.props;
        if (!layout || !layout.Layout || !layout.Layout.IsPivot) {
            return;
        }

        let parentRelations = flowRelations.filter(x => x.ChildFlowItemId === flowItemId);

        //handles multi-export node
        if (parentRelations.length === 1 && flowMultiExportsByFlowItemId) {
            const parentFlowItemId = parentRelations[0].ParentFlowItemId;
            if (flowMultiExportsByFlowItemId[parentFlowItemId]) {
                parentRelations = flowRelations.filter(x => x.ChildFlowItemId === parentFlowItemId);
            }
        }

        const regex = /[^a-zA-Z0-9_ ]/g;

        for (const parent of parentRelations) {
            const parentLabel = parentLabels.find(x => x.FlowRelationId == parent.FlowRelationId);
            if (!parentLabel && parent.ParentFlowItemId != 0) {
                const flowItem = flowItems[parent.ParentFlowItemId]; 
                if (flowItem) {
                    const labelName = flowItem.FlowItemName.replace(regex, "");
                    this.props.addFlowRelationParentLabel(parent.FlowRelationId, labelName);
                }
            }
        }
    };

    renderOfferColumnHeaders = requiredOfferCodes => {
        const headers = [];
        for (const offerCode of requiredOfferCodes) {
            headers.push(
                <TableCell
                    className={"muiTableCellFont"}
                    key={"offer-code-header-" + offerCode.Id}
                    style={{ fontSize: "16px !important" }}
                    align="left"
                >
                    {offerCode.VariableName}
                </TableCell>
            );
        }
        return headers;
    };

    renderSingleFileAudienceName = () => {
        const { flowItemId, flowItems, flowExport, enabledFeatures } = this.props;
        const regex = /[^a-zA-Z0-9_ ]/g;
        const flowItem = flowItems[flowItemId];
        const labelName = flowItem.FlowItemName.replace(regex, "");
        const headers = [];
        const hasDestinationSeparateFilesFeature = enabledFeatures.includes("flow-destination-level-splits-all-offers");
        let audienceFileName = flowExport.AudienceFileName;
        if (audienceFileName == null) {
            audienceFileName = labelName;
        }
        if (flowExport.IsSeperateFiles || !flowExport.IsSplitOn || !hasDestinationSeparateFilesFeature) {
            return;
        }
        headers.push(
            <TableContainer
                style={{
                    display: "flex",
                    overflow: "auto",
                    minWidth: "750px",
                }}
            >
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ fontSize: 18 }} align="right">
                                Audience Name:
                            </TableCell>
                            <TableCell style={{ fontSize: 12 }} align="left">
                                <TextField
                                    style={{
                                        fontSize: 16,
                                        width: "95%",
                                        marginLeft: "15px",
                                        top: "-5px",
                                        position: "relative",
                                        margin: "6px",
                                    }}
                                    key={"AudienceFileName" + flowItemId}
                                    type="text"
                                    value={audienceFileName}
                                    inputProps={{
                                        style: { fontSize: "16px" },
                                    }}
                                    onChange={e => {
                                        const regex = /[^a-zA-Z0-9_ ]/g;
                                        const newValue = e.target.value.replace(regex, "");
                                        this.props.updateAttribute(
                                            "flowExports",
                                            flowExport.FlowExportId,
                                            "AudienceFileName",
                                            newValue,
                                            true
                                        );
                                        // this.props.dispatch(invalidateItemAndChildren(flowItemId));
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
        return headers;
    };

    renderOfferColumns = (offerData, requiredOfferCodes) => {
        const { flowItemClientVariables } = this.props;
        const columns = [];
        for (const offerCode of requiredOfferCodes) {
            const identifier = `${offerData.ParentFlowItemId}-${offerCode.Id}`;

            if (offerCode.VariableScope === "DestinationOffer" && offerCode.VariableKind === "field") {
                const flowItemCV = flowItemClientVariables.filter(
                    x =>
                        x.FlowItemId == offerData.ParentFlowItemId &&
                        x.VariableId == offerCode.Id &&
                        x.ChildFlowItemId == this.props.flowItemId
                )[0];
                const fieldId = flowItemCV ? flowItemCV.VariableValue.FieldId : null;

                const field = this.renderEditableField(flowItemCV, offerCode.Id, offerData.ParentFlowItemId, fieldId);

                columns.push(
                    <TableCell
                        className={"muiTableCellFont"}
                        key={"field" + offerData.ParentFlowItemId + offerCode.Id}
                        style={{ fontSize: "16px !important" }}
                    >
                        {field}
                    </TableCell>
                    // <td key={"field" + offerData.ParentFlowItemId + offerCode.Id}>{field}</td>
                );
            } else {
                columns.push(
                    <TableCell
                        className={"muiTableCellFont"}
                        key={"offer-code-cell-" + identifier}
                        style={{ fontSize: "16px !important" }}
                    >
                        {this.renderEditableString(
                            offerCode.Id,
                            offerData.ParentFlowItemId,
                            identifier,
                            offerCode.VariableGroupId,
                            offerCode.VariableScope
                        )}
                    </TableCell>
                );
            }
        }
        return columns;
    };

    renderEditableField = (flowItemClientVariable, variableId, flowItemId, fieldId) => {
        const {
            permissions: {
                item: { canEdit },
            },
            flow,
        } = this.props;
        return (
            <FieldPicker
                value={fieldId}
                disabled={!canEdit || (flow && flow.IsLocked)}
                onChange={newFieldId => {
                    const newValueObject: VariableValueType = {
                        Kind: "field",
                        ValueString: null,
                        ValueDate: null,
                        FieldId: newFieldId,
                    };
                    this.onVariableChange(flowItemClientVariable, variableId, flowItemId, newValueObject);
                }}
            />
        );
    };

    onVariableChange = (
        flowItemClientVariable: ?FlowItemClientVariableD,
        variableId: number,
        parentFlowItemId: number,
        variableValue: VariableValueType
    ) => {
        const {
            selectedFlow,
            updateAttribute,
            newFlowItemClientVariable,
            deleteFlowItemClientVariable,
            flowItemClientVariables,
            flowItemId,
        } = this.props;
        if (flowItemClientVariable) {
            if (variableValue) {
                if (variableValue.ValueString != "" || variableValue.FieldId) {
                    updateAttribute(
                        "flowItemClientVariables",
                        flowItemClientVariable.FlowItemClientVariableId,
                        "VariableValue",
                        variableValue,
                        true
                    );
                } else {
                    deleteFlowItemClientVariable(flowItemClientVariable.FlowItemClientVariableId);
                }
            } else {
                deleteFlowItemClientVariable(flowItemClientVariable.FlowItemClientVariableId);
            }
        } else if (
            flowItemClientVariables.filter(
                x => x.VariableId == variableId && x.FlowItemId == parentFlowItemId && x.ChildFlowItemId == flowItemId
            ).length == 0
        ) {
            newFlowItemClientVariable(variableId, selectedFlow, parentFlowItemId, flowItemId, variableValue);
        }

        this.invalidate();
    };

    updateExportCustomDedupe = (customDedupe: boolean) => {
        const { defaultDedupeKey, flowExport, clientFieldObject, flowSVDedupe } = this.props;

        if (flowExport) {
            this.props.updateAttribute("flowExports", flowExport.FlowExportId, "CustomDedupe", customDedupe);
        }

        if (customDedupe && flowSVDedupe && defaultDedupeKey) {
            this.props.updateAttribute("flowSVDedupes", flowSVDedupe.FlowSVDedupeId, "SVField", defaultDedupeKey, true);
        } else if (!customDedupe && flowSVDedupe && clientFieldObject) {
            this.props.updateAttribute(
                "flowSVDedupes",
                flowSVDedupe.FlowSVDedupeId,
                "SVField",
                clientFieldObject.FieldName,
                true
            );
        }
    };

    invalidate = () => {
        const { flowItemId, invalidateItemAndChildren } = this.props;
        invalidateItemAndChildren(flowItemId);
    };

    splitCollapseChanged = parentFlowItemId => {
        // if the current splitExpandedId is the same parentFlowItemId, it will just collapse
        const { splitExpandedId } = this.state;
        let newValue = 0;
        if (splitExpandedId != parentFlowItemId) {
            newValue = parentFlowItemId;
        }
        this.setState({ splitExpandedId: newValue });
    };

    renderFlowSegmentSplitPreviewCell = parentFlowItemId => {
        const { flowSegmentSplits } = this.props;
        const parentSegmentSplits = flowSegmentSplits.filter(x => x.ParentFlowItemId == parentFlowItemId);
        let result = "";
        for (const split of parentSegmentSplits) {
            if (result.length > 0) {
                result += "/";
            }
            if (split.AbsoluteOrRelative == "relative") {
                result += split.RelativePercentRows.toString() + "%";
            } else {
                result += split.AbsoluteNumRows.toString();
            }
        }
        if (!result) {
            return null;
        }
        return (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100px" }}>{result}</div>
                &nbsp;
                <Tooltip title={result}>
                    <Icon style={{ fontSize: 18 }}>info</Icon>
                </Tooltip>
            </div>
        );
    };

    canShowCounts = () => {
        const { destinations, destinationId } = this.props;
        if (destinations) {
            if (destinations.length > 0) {
                const partners = destinations.filter(d => d.PartnerAccessId == destinationId);
                return partners.length > 0 ? partners[0].CanShowCount : false;
            }
        }
        return true;
    };

    render() {
        const {
            flowItemId,
            permissions,
            permissions: {
                item: { canEdit },
            },
            flow,
            flowExport,
            flowOutput,
            flowSVDedupe,
            isAdditionalTemplate,
            flowExportTemplateFields,
            isPinterestTemplate,
            isTikTokTemplate,
            flowExportPinterestTemplateFields,
            flowExportTikTokTemplateFields,
            isTradeDeskTemplate,
            flowExportTradeDeskTemplateFields,
            flowExportFreewheelDriverFileFields,
            isSnowflakeTable,
            splitOverwrite,
            layout,
            enabledFeatures,
            defaultDedupeKey,
            variableGroups,
            deploySetting,
        } = this.props;

        const hasDestinationSplitFeature = enabledFeatures.includes("flow-destination-level-splits");
        const hasDestinationSeparateFilesFeature = enabledFeatures.includes("flow-destination-level-splits-all-offers");
        const showMinPenetration = enabledFeatures.includes("export-min-penetration");
        let counter = 0;
        const { splitExpandedId } = this.state;
        const requiredOfferCodes = this.getRequiredOfferCodes();

        let colSpan = 2 + requiredOfferCodes.length;
        let offerOptions = requiredOfferCodes.map(x => (
            <MenuItem key={"Offer_" + x.Id} value={x.Id}>
                {x.VariableName}
            </MenuItem>
        ));
        // new flows things
        let offerData = this.renderOfferTable();
        const handleMinPenetrationChange = newVal => {
            if (newVal > 100) {
                newVal = 100;
            } else if (newVal < 1) {
                newVal = 1;
            }
            this.props.updateAttribute("flowExports", flowExport.FlowExportId, "MinPenetration", newVal);
            this.props.invalidateItemAndChildren(this.props.flowItemId);
        };

        const exportOfferEdit = (
            <>
                {flowExport && isAdditionalTemplate && (
                    <ExportTemplateFields
                        destinationId={flowExport.DestinationId}
                        flowExportTemplateFields={flowExportTemplateFields}
                        canEdit={canEdit}
                    />
                )}
                {flowExport && isPinterestTemplate && (
                    <ExportPinterestTemplateFields
                        destinationId={flowExport.DestinationId}
                        deliveryTypeId={flowExport.DeliveryTypeId}
                        flowExportPinterestTemplateFields={flowExportPinterestTemplateFields}
                        canEdit={canEdit}
                    />
                )}
                {flowExport && isTikTokTemplate && (
                    <ExportTikTokTemplateFields
                        destinationId={flowExport.DestinationId}
                        deliveryTypeId={flowExport.DeliveryTypeId}
                        flowExportTikTokTemplateFields={flowExportTikTokTemplateFields}
                        canEdit={canEdit}
                    />
                )}
                {flowExport && isTradeDeskTemplate && (
                    <ExportTradeDeskTemplateFields
                        destinationId={flowExport.DestinationId}
                        tapadDeliveryTypeId={flowExport.DeliveryTypeId}
                        flowExportTradeDeskTemplateFields={flowExportTradeDeskTemplateFields}
                        canEdit={canEdit}
                    />
                )}
                {flowExport && deploySetting == DeploySettings.DeployFreewheelDriverFile && (
                    <FlowExportFreewheelDriverFileForm
                        flowItemId={this.props.flowItemId}
                        flowExportFreewheelDriverFileFields={flowExportFreewheelDriverFileFields}
                        deploySetting={DeploySettings.DeployFreewheelDriverFile}
                    />
                )}
                {flowExport && deploySetting == DeploySettings.DeployMagniteDriverFile && (
                    <FlowExportFreewheelDriverFileForm
                        flowItemId={this.props.flowItemId}
                        flowExportFreewheelDriverFileFields={flowExportFreewheelDriverFileFields}
                        deploySetting={DeploySettings.DeployMagniteDriverFile}
                    />
                )}
                {flowExport && isSnowflakeTable && (
                    <FlowItemSnowflakeTables
                        entityPluralName="flowExports"
                        flowItemId={flowExport.FlowExportId}
                        destinationId={flowExport.DestinationId}
                        snowflakeSettingsId={flowExport.SnowflakeSettingsId}
                        snowflakeSettingsTable={flowExport.SnowflakeTable}
                        snowflakeSettingsAppend={flowExport.AppendToExistingTable}
                    />
                )}
                {flowExport && deploySetting == DeploySettings.DeployXandrTemplate && (
                    <XandrDriverFields
                        flowItemId={flowExport.FlowItemId}
                        canEdit={canEdit}
                        tapadDeliveryTypeId={flowExport.DeliveryTypeId}
                    />
                )}
                {flowOutput == null && (
                    <div className="flow-edit-header" style={{ flexWrap: "wrap" }}>
                        <div style={{ display: "flex", flexGrow: 1 }}>
                            <p>Additional Configurations</p>
                        </div>
                        {showMinPenetration && (
                            <div className="flow-edit-header-actions" style={{ display: "flex", marginLeft: "10px" }}>
                                <div style={{ display: "flex", paddingLeft: "15px" }}>
                                    <NumericalTextField
                                        className="row m-t"
                                        label={"Min Penetration:"}
                                        value={flowExport.MinPenetration}
                                        onBlur={e => {
                                            handleMinPenetrationChange(e.target.value);
                                        }}
                                        style={{ width: 150 }}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="flow-edit-header-actions" style={{ display: "flex", marginLeft: "20px" }}>
                            <FlowItemMaxQtyToggle
                                flowItemId={flowItemId}
                                disabled={!canEdit || (flow && flow.IsLocked)}
                            />
                            <OfferDedupeToggle
                                flowItemId={flowItemId}
                                onChangeCallback={this.onChangeCallback}
                                updateCustomDedupe={newValue => this.updateExportCustomDedupe(newValue)}
                                dedupeKey={flowSVDedupe ? flowSVDedupe.SVField : defaultDedupeKey}
                                isDisabled={!canEdit || (flow && flow.IsLocked)}
                            />
                            {flowSVDedupe && (
                                <CustomDedupeToggle
                                    customDedupe={flowExport.CustomDedupe}
                                    updateCustomDedupe={e => this.updateExportCustomDedupe(e.target.checked)}
                                    enabledFeatures={enabledFeatures}
                                    disabled={!canEdit || (flow && flow.IsLocked)}
                                />
                            )}
                            {hasDestinationSplitFeature && (
                                <FlowExportSplitToggle
                                    flowExport={flowExport}
                                    disabled={!canEdit || (flow && flow.IsLocked)}
                                    splitOverwrite={splitOverwrite}
                                    destinationOffers={requiredOfferCodes}
                                />
                            )}
                            {((splitOverwrite && hasDestinationSplitFeature) ||
                                (hasDestinationSplitFeature &&
                                    flowExport.IsSplitOn &&
                                    hasDestinationSeparateFilesFeature)) && (
                                <FlowExportSeperateFilesToggle
                                    flowExport={flowExport}
                                    splitOverwrite={splitOverwrite}
                                    //disabled={!canEdit || (flow && flow.IsLocked) || (splitOverwrite && hasDestinationSplitFeature)}
                                    disabled={!canEdit || (flow && flow.IsLocked)}
                                />
                            )}
                            {flowExport.IsSplitOn &&
                                !enabledFeatures.includes("flow-destination-level-splits-all-offers") &&
                                requiredOfferCodes.length > 0 && (
                                    <div style={{ display: "flex", marginLeft: "20px" }}>
                                        <div style={{ display: "flex", placeSelf: "flex-end" }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    fontSize: "17px",
                                                    position: "relative",
                                                    top: "10px",
                                                    marginRight: "10px",
                                                }}
                                            >
                                                <strong>Split Offer&nbsp;</strong>
                                                <Tooltip
                                                    title={`When turned on, segment split labels will populate this offer code on deployment.`}
                                                    style={{ fontSize: "14px", marginRight: "10px" }}
                                                >
                                                    <Icon>info</Icon>
                                                </Tooltip>

                                                <Select
                                                    value={flowExport.SelectedOfferId || ""}
                                                    onChange={e =>
                                                        this.props.updateAttribute(
                                                            "flowExports",
                                                            flowExport.FlowExportId,
                                                            "SelectedOfferId",
                                                            e.target.value
                                                        )
                                                    }
                                                    displayEmpty={true}
                                                    disabled={flowExport && !flowExport.IsSplitOn}
                                                >
                                                    <MenuItem value={null} />
                                                    {offerOptions}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                )}

                <Divider style={{ margin: "20px auto" }} />
                <div className="flow-edit-body">
                    {this.renderSingleFileAudienceName()}

                    <TableContainer
                        style={{
                            display: "flex",
                            overflow: "auto",
                            minWidth: "750px",
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow className={"muiTableCellFont"} style={{ fontSize: "16px !important" }}>
                                    {flowExport && flowExport.IsSplitOn && colSpan++ && (
                                        <TableCell
                                            className={"muiTableCellFont"}
                                            style={{ width: "100px", fontSize: "16px !important" }}
                                            align="right"
                                        ></TableCell>
                                    )}
                                    <TableCell
                                        className={"muiTableCellFont"}
                                        style={{ fontSize: "16px !important" }}
                                        align="left"
                                    >
                                        Item
                                    </TableCell>
                                    {flowSVDedupe && colSpan++ && (
                                        <TableCell
                                            className={"muiTableCellFont"}
                                            style={{
                                                fontSize: "16px !important",
                                                width: "100px",
                                            }}
                                            align="center"
                                        >
                                            Priority
                                        </TableCell>
                                    )}
                                    <TableCell
                                        className={"muiTableCellFont"}
                                        style={{
                                            fontSize: "16px !important",
                                            width: "170px",
                                        }}
                                        align="right"
                                    >
                                        Input Quantity
                                    </TableCell>
                                    {!flowOutput && colSpan++ && this.canShowCounts() && (
                                        <TableCell
                                            className={"muiTableCellFont"}
                                            style={{
                                                fontSize: "16px !important",
                                                width: "170px",
                                            }}
                                            align="right"
                                        >
                                            Matched Quantity
                                        </TableCell>
                                    )}
                                    {layout && layout.Layout && layout.Layout.IsPivot && colSpan++ && (
                                        <TableCell
                                            className={"muiTableCellFont"}
                                            style={{ fontSize: "16px !important" }}
                                            align="left"
                                        >
                                            Output Column Name
                                        </TableCell>
                                    )}
                                    {!flowOutput && flowExport && flowExport.IsMaxQtyOn && colSpan++ && (
                                        <TableCell
                                            className={"muiTableCellFont"}
                                            style={{
                                                fontSize: "16px !important",
                                                width: "170px",
                                            }}
                                            align="right"
                                        >
                                            Max Quantity
                                        </TableCell>
                                    )}
                                    {!flowOutput && flowExport && colSpan++ && this.canShowCounts() && (
                                        <TableCell
                                            className={"muiTableCellFont"}
                                            style={{
                                                fontSize: "16px !important",
                                                width: "170px",
                                            }}
                                            align="right"
                                        >
                                            Final Quantity 1
                                        </TableCell>
                                    )}
                                    {!flowOutput && flowExport && flowExport.IsSplitOn && colSpan++ && (
                                        <TableCell
                                            className={"muiTableCellFont"}
                                            style={{ fontSize: "16px !important" }}
                                            align="right"
                                        >
                                            Split
                                        </TableCell>
                                    )}
                                    {!(
                                        enabledFeatures.includes("flow-destination-level-splits-all-offers") &&
                                        flowExport.IsSplitOn
                                    ) && this.renderOfferColumnHeaders(requiredOfferCodes)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {offerData.map(x => (
                                    <React.Fragment key={"fragment-" + x.ParentFlowItemId + counter++}>
                                        <TableRow
                                            className={"muiTableCellFont"}
                                            key={"row-" + x.ParentFlowItemId}
                                            style={{ fontSize: "16px !important" }}
                                        >
                                            {flowExport && flowExport.IsSplitOn && (
                                                <TableCell
                                                    className={"muiTableCellFont"}
                                                    style={{ width: "100px", fontSize: "16px !important" }}
                                                    align="right"
                                                >
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => this.splitCollapseChanged(x.ParentFlowItemId)}
                                                    >
                                                        {x.ParentFlowItemId != splitExpandedId ? (
                                                            <span className="material-icons">keyboard_arrow_down</span>
                                                        ) : (
                                                            <span className="material-icons">keyboard_arrow_up</span>
                                                        )}
                                                    </IconButton>
                                                </TableCell>
                                            )}
                                            <TableCell
                                                className={"muiTableCellFont"}
                                                style={{ fontSize: "16px !important" }}
                                            >
                                                <div className="parent-item-label">{x.ItemName}</div>
                                            </TableCell>
                                            {flowSVDedupe && (
                                                <TableCell
                                                    className={"muiTableCellFont"}
                                                    style={{
                                                        fontSize: "16px !important",
                                                        width: "100px",
                                                    }}
                                                >
                                                    {this.renderPrioritySelect(x.ParentFlowItemId)}
                                                </TableCell>
                                            )}
                                            <TableCell
                                                className={"muiTableCellFont"}
                                                style={{
                                                    fontSize: "16px !important",
                                                    width: "170px",
                                                }}
                                                align="right"
                                            >
                                                <div className="parent-item-label">
                                                    {x.Quantity ? addCommas(x.Quantity) : ""}
                                                </div>
                                            </TableCell>
                                            {!flowOutput && this.canShowCounts() && (
                                                <TableCell
                                                    className={"muiTableCellFont"}
                                                    style={{
                                                        fontSize: "16px !important",
                                                        width: "170px",
                                                    }}
                                                    align="right"
                                                >
                                                    <div className="parent-item-label">
                                                        {this.renderOutputQuantity(x.OutputQty)}
                                                    </div>
                                                </TableCell>
                                            )}
                                            {layout && layout.Layout && layout.Layout.IsPivot && (
                                                <TableCell
                                                    className={"muiTableCellFont"}
                                                    style={{ fontSize: "16px !important" }}
                                                >
                                                    {this.renderParentLabelColumn(
                                                        x.FlowRelationParentLabelId,
                                                        x.ParentLabel
                                                    )}
                                                </TableCell>
                                            )}
                                            {!flowOutput && flowExport && flowExport.IsMaxQtyOn && (
                                                <TableCell
                                                    className={"muiTableCellFont"}
                                                    style={{
                                                        fontSize: "16px !important",
                                                        width: "170px",
                                                    }}
                                                    align="right"
                                                >
                                                    {this.renderMaxQuantity(x.ParentFlowItemId, x.MaxQty)}
                                                </TableCell>
                                            )}
                                            {!flowOutput && flowExport && this.canShowCounts() && (
                                                <TableCell
                                                    className={"muiTableCellFont"}
                                                    style={{
                                                        fontSize: "16px !important",
                                                        width: "170px",
                                                    }}
                                                    align="right"
                                                >
                                                    <div className="parent-item-label">
                                                        {this.renderOutputQuantity(x.FinalQty)}
                                                    </div>
                                                </TableCell>
                                            )}
                                            {!flowOutput && flowExport && flowExport.IsSplitOn && (
                                                <TableCell
                                                    className={"muiTableCellFont"}
                                                    style={{
                                                        fontSize: "16px !important",
                                                        width: "150px",
                                                    }}
                                                    align="right"
                                                >
                                                    {this.renderFlowSegmentSplitPreviewCell(x.ParentFlowItemId)}
                                                </TableCell>
                                            )}
                                            {!(
                                                enabledFeatures.includes("flow-destination-level-splits-all-offers") &&
                                                flowExport.IsSplitOn
                                            ) && this.renderOfferColumns(x, requiredOfferCodes, variableGroups)}
                                        </TableRow>
                                        {flowExport &&
                                            (flowExport.IsSplitOn ||
                                                (splitOverwrite &&
                                                    flowExport.IsSplitOn &&
                                                    flowExport.IsSeperateFiles)) && (
                                                <TableRow
                                                    className={"muiTableCellFont"}
                                                    style={{ fontSize: "16px !important" }}
                                                >
                                                    <TableCell
                                                        className={"muiTableCellFont"}
                                                        style={{
                                                            paddingBottom: 0,
                                                            paddingTop: 0,
                                                            fontSize: "16px !important",
                                                        }}
                                                        colSpan={colSpan}
                                                    >
                                                        <Collapse
                                                            in={x.ParentFlowItemId == splitExpandedId}
                                                            timeout="auto"
                                                            unmountOnExit
                                                        >
                                                            <FlowExportSplit
                                                                flowItemId={flowItemId}
                                                                parentFlowItemId={x.ParentFlowItemId}
                                                                //disabled={!canEdit || (flow && flow.IsLocked)}
                                                                disabled={
                                                                    !canEdit ||
                                                                    (flow && flow.IsLocked) ||
                                                                    (splitOverwrite && !flowExport.IsSplitOn)
                                                                }
                                                                destinationOffers={requiredOfferCodes}
                                                                //splitOverwrite={splitOverwrite}
                                                            />
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {flowExport && flowSVDedupe && flowExport.CustomDedupe ? (
                        <>
                            <br />
                            <br />
                            <br />
                            <FlowSVDedupeEdit
                                /* Setting 'key' is a hack to force the component to reload
                                    to fix a focus issue on the Switch component */
                                key={"flowSVDedupeEdit" + flowItemId}
                                flowItemId={flowItemId}
                                canEdit={permissions.item.canEdit && !this.props.preventOverrideDedupeExport}
                                containerHeight={{ minHeight: "200px" }}
                            />
                        </>
                    ) : null}
                </div>
            </>
        );
        return exportOfferEdit;
    }
}

export const makeGetFlowExportTemplateFields = () =>
    createSelector(
        state => getFlowExportTemplateFieldsByFlowItemId(state),
        (_, props) => props.flowItemId,
        (
            flowExportTemplateFieldsByItemId: FlowExportTemplateFieldsByItemId,
            flowItemId: number
        ): Array<FlowExportTemplateFields> => flowExportTemplateFieldsByItemId[flowItemId]
    );

export const makeGetFlowExportPinterestTemplateFields = () =>
    createSelector(
        state => getFlowExportPinterestTemplateFieldsByFlowItemId(state),
        (_, props) => props.flowItemId,
        (
            flowExportPinterestTemplateFieldsByItemId: FlowExportPinterestTemplateFieldsByItemId,
            flowItemId: number
        ): Array<FlowExportPinterestTemplateFields> => flowExportPinterestTemplateFieldsByItemId[flowItemId]
    );

export const makeGetFlowExportTikTokTemplateFields = () =>
    createSelector(
        state => getFlowExportTikTokTemplateFieldsByFlowItemId(state),
        (_, props) => props.flowItemId,
        (
            flowExportTikTokTemplateFieldsByItemId: FlowExportTikTokTemplateFieldsByItemId,
            flowItemId: number
        ): Array<FlowExportTikTokTemplateFields> => flowExportTikTokTemplateFieldsByItemId[flowItemId]
    );

export const makeGetFlowExportTradeDeskTemplateFields = () =>
    createSelector(
        state => getFlowExportTradeDeskTemplateFieldsByFlowItemId(state),
        (_, props) => props.flowItemId,
        (
            flowExportTradeDeskTemplateFieldsByItemId: FlowExportTradeDeskTemplateFieldsByItemId,
            flowItemId: number
        ): Array<FlowExportTradeDeskTemplateFields> => flowExportTradeDeskTemplateFieldsByItemId[flowItemId]
    );

export const makeGetFlowExportTaxonomyFields = () =>
    createSelector(
        state => getFlowExportTaxonomyFileFieldsByFlowItemId(state),
        (_, props) => props.flowItemId,
        (
            flowExportTaxonomyFieldsByItemId: FlowExportTaxonomyFileFieldsByItemId,
            flowItemId: number
        ): Array<FlowExportTaxonomyFileFieldsByItemId> => flowExportTaxonomyFieldsByItemId[flowItemId]
    );

export const makeGetFlowOfferMergeForExport = () =>
    createSelector(
        state => getFlowOfferMergesByFlowItemId(state),
        (_, props) => props.flowExportId,
        (flowOfferMergesByFlowItemId: FlowOfferMergesByItemId, flowItemId: number): Array<FlowOfferMerge> =>
            flowOfferMergesByFlowItemId[flowItemId]
    );

export const makeGetParentFlowMultiExport = () =>
    createSelector(
        state => getFlowMultiExportArray(state),
        (_, props) => props.flowItemId,
        (multiExports: Array<FlowMultiExport>): ?FlowMultiExport => {
            const candidates = multiExports; //.filter(x => x.ChildFlowItemId == flowItemId);
            return candidates.length == 0 ? null : candidates[0];
        }
    );
const mapStateToProps: MapStateToProps<*, *, *> = () => (state, ownProps) => {
    const flowExportsByFlowItemId = getFlowExportsByFlowItemId(state);
    const flowOutputsByFlowItemId = getFlowOutputsByFlowItemId(state);
    const flowMultiExportsByFlowItemId = getFlowMultiExportsByFlowItemId(state);
    const getFlowOfferMerge = makeGetFlowOfferMerge();
    const getFlowOfferMergeExport = makeGetFlowOfferMergeForExport();
    const getFlowMultiExportParent = makeGetParentFlowMultiExport();
    const getPermissionsItemFromProps = makeGetPermissionsItemFromProps();
    const getFlowSVDedupe = makeGetFlowSVDedupe();
    const getFlowExportTemplateFields = makeGetFlowExportTemplateFields();
    const getFlowExportPinterestTemplateFields = makeGetFlowExportPinterestTemplateFields();
    const getFlowExportTikTokTemplateFields = makeGetFlowExportTikTokTemplateFields();
    const getFlowExportTradeDeskTemplateFields = makeGetFlowExportTradeDeskTemplateFields();
    const getFlowExportTaxonomyFields = makeGetFlowExportTaxonomyFields();
    const freeWheelDriverFieldsByFlowItemId = getFlowExportFreewheelDriverFileFieldsByFlowItemId(state);

    return {
        selectedFlow: state.selected.flow,
        selectedCompany: state.session.companyId,
        destinationOffers: state.flowExportObjects.destinationOffers[ownProps.destinationId] || null,
        flowItems: state.flowItems.byId,
        flowRelations: getFlowRelationsForSelectedFlow(state),
        parentLabels: getFlowRelationParentLabelsForSelectedFlow(state),
        flowItemClientVariables: getFlowItemClientVariablesForSelectedFlow(state),
        layout: state.layoutObjects.objects.filter(x => x.Layout.LayoutID == ownProps.layoutId)[0] || {},
        permissions: getPermissionsItemFromProps(state, ownProps),
        flowSVDedupe: getFlowSVDedupe(state, ownProps),
        flowOfferMerges: getFlowOfferMerge(state, ownProps) || [],
        flowExportOfferMerges: getFlowOfferMergeExport(state, ownProps) || [],
        flowMultiExports: getFlowMultiExportParent(state, ownProps) || [],
        flowMultiExportsByFlowItemId: flowMultiExportsByFlowItemId || [],
        variableGroups: state.vars.variableGroupings || [],
        flowExport: flowExportsByFlowItemId[ownProps.flowExportId] || null,
        flowOutput: flowOutputsByFlowItemId[ownProps.flowItemId] || null,
        splitOverwrite: ownProps.splitOverwrite,
        enabledFeatures: state.session.enabledFeatures || [],
        flow: state.flows.byId[state.selected.flow],
        flowExportTemplateFields: getFlowExportTemplateFields(state, ownProps) || [],
        flowExportPinterestTemplateFields: getFlowExportPinterestTemplateFields(state, ownProps) || [],
        flowExportTikTokTemplateFields: getFlowExportTikTokTemplateFields(state, ownProps) || [],
        flowExportTradeDeskTemplateFields: getFlowExportTradeDeskTemplateFields(state, ownProps) || [],
        flowExportTaxonomyFileFields: getFlowExportTaxonomyFields(state, ownProps) || [],
        flowExportFreewheelDriverFileFields: freeWheelDriverFieldsByFlowItemId[ownProps.flowItemId] || [],
        flowSegmentSplits: getFlowSegmentSplitsForSelectedFlowItem(state),
        flowSegmentSplitOffers: getFlowSegmentSplitOffersForSelectedFlowItem(state),
        destination: (state.vars.destinations || []).find(x => x.PartnerAccessId == ownProps.destinationId),
        deliveryTypeId: (state.vars.tapadDeliveryTypes || []).find(x => x.deliveryTypeId == ownProps.deliveryTypeId),
        defaultDedupeKey: state.session.defaultDedupeKey,
        clientFieldObject: state.layoutObjects.clientFieldObject,
        companies: state.session.companies,
        preventOverrideDedupeExport: state.session.companyIsPreventOverrideDedupeExport,
        destinations: state.vars.destinations,
    };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(Object.assign({}, flowActions, flowExportXandrDriverFieldActions), dispatch);
const FlowExportOfferEditC = connect(mapStateToProps, mapDispatchToProps)(FlowExportOfferEdit);

export default FlowExportOfferEditC;
