import { SiteCustomization } from "../types/types";

export default function siteCustomization(
    customName: string,
    defaultValue: string,
    customizations: Array<SiteCustomization>
): string {
    let output = defaultValue;
    if (customizations != null) {
        let customValue = customizations.filter(x => x.Name == customName);

        if (customValue.length > 0 && customValue[0].Value != "") {
            output = customValue[0].Value;
        }
    }
    return output;
}
