import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { updateAttribute } from "../../../../actions/flowActions";

import Table from "@material-ui/core/Table";
import TableHeader from "../../../material-components/Table/TableHeader";
import TableBody from "../../../material-components/Table/TableBody";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const ExportTemplateFields = ({ destinationId, flowExportTemplateFields, canEdit }) => {
    const dispatch = useDispatch();

    // state
    const [templateRows, setTemplateRows] = useState([]);
    const [emailErrors, setEmailErrors] = useState({});

    // props
    const flow = useSelector(state => state.flows.byId[state.selected.flow]);
    const flowItems = useSelector(state => state.flowItems.byId);
    const enabledFeatures = useSelector(state => state.session.enabledFeatures || []);
    const newMetaTemplate = enabledFeatures.includes("new-metadata-driver-field");

    const renderTemplateRows = useCallback(() => {
        let rows = [];
        if (flowExportTemplateFields != null) {
            for (const template of flowExportTemplateFields) {
                const parentItem = flowItems[template.ParentFlowItemId];
                rows.push({
                    TemplateId: template.FlowExportTemplateId,
                    ParentFlowItemId: template.ParentFlowItemId,
                    ItemName: parentItem.FlowItemName,
                    Description: template.Description || "",
                    AdAccountIds: template.AdAccountIds || "",
                    CustomerFileSource: newMetaTemplate ? "PARTNER_PROVIDED_ONLY" : template.CustomerFileSource || "",
                    EmailAddress: template.EmailAddress || "",
                });
            }
        }
        setTemplateRows(rows);
    }, [flowExportTemplateFields, flowItems, newMetaTemplate]);

    // effects
    useEffect(() => {
        renderTemplateRows();
    }, [destinationId, flowExportTemplateFields, renderTemplateRows]);

    const renderTemplateTable = () => {
        const tableHeaders = [
            ["ItemName", "Item", "smDown", "left"],
            ["Description", "Description", "smDown", "left"],
            ["AdAccountIds", "Ad Account Ids", "smDown", "left"],
            ["CustomerFileSource", "Customer File Source", "smDown", "left"],
        ];

        if (newMetaTemplate) {
            tableHeaders.push(["EmailAddress", "Notification Emails", "smDown", "left"]);
        }

        const emptyMessage = ["EmptyMessage", "No Driver Fields"];

        const data = templateRows.map((item, index) => {
            const baseRow = [
                ["ItemName", item.ItemName, "smDown", "left"],
                ["Description", renderDescriptionColumn(item, index), "smDown", "left"],
                ["AdAccountIds", renderAdIdColumn(item, index), "smDown", "left"],
                [
                    "CustomerFileSource",
                    newMetaTemplate ? "PARTNER_PROVIDED_ONLY" : renderFileSourceColumn(item),
                    "smDown",
                    "left",
                ],
            ];

            if (newMetaTemplate) {
                baseRow.push(["EmailAddress", renderEmailAddressColumn(item, index), "smDown", "left"]);
            }

            return baseRow;
        });

        return (
            <div id="templateFieldsTable">
                <Table size={"medium"}>
                    <TableHeader style={{ fontSize: "20px" }} headers={tableHeaders} ignoreHeaders={tableHeaders} />
                    <TableBody
                        style={{ fontSize: "18px" }}
                        data={data}
                        page={0}
                        rowsPerPage={data.length}
                        emptyMessage={emptyMessage}
                    />
                </Table>
            </div>
        );
    };

    const renderDescriptionColumn = (item, index) => {
        const rows = [...templateRows];
        const descriptionColumn = (
            <TextField
                key={"template-description-" + item.TemplateId}
                type="text"
                value={item.Description}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    style: { fontSize: "16px" },
                }}
                onChange={e => {
                    rows[index].Description = e.target.value;
                    return setTemplateRows(rows);
                }}
                onBlur={e => handleChange(item.TemplateId, "Description", e)}
            />
        );

        return descriptionColumn;
    };

    const renderAdIdColumn = (item, index) => {
        const rows = [...templateRows];
        const adIdColumn = (
            <TextField
                key={"template-adid-" + item.TemplateId}
                type="text"
                value={item.AdAccountIds}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    style: { fontSize: "16px" },
                }}
                onChange={e => {
                    rows[index].AdAccountIds = checkAdIds(e.target.value);
                    return setTemplateRows(rows);
                }}
                onBlur={e => handleChange(item.TemplateId, "AdAccountIds", e)}
            />
        );

        return adIdColumn;
    };

    const checkAdIds = value => value.replace(/[^0-9|]/g, "");

    const renderFileSourceColumn = item => {
        const sourceColumn = (
            <Select
                key={"template-source-" + item.TemplateId}
                style={{ fontSize: "14px", width: "100%" }}
                value={item.CustomerFileSource}
                onChange={e => handleChange(item.TemplateId, "CustomerFileSource", e)}
                disabled={!canEdit || (flow && flow.IsLocked)}
                displayEmpty
            >
                <MenuItem key={0} value={"PARTNER_PROVIDED_ONLY"}>
                    PARTNER_PROVIDED_ONLY
                </MenuItem>
                <MenuItem key={1} value={"BOTH_USER_AND_PARTNER_PROVIDED"}>
                    BOTH_USER_AND_PARTNER_PROVIDED
                </MenuItem>
                <MenuItem key={2} value={"USER_PROVIDED_ONLY"}>
                    USER_PROVIDED_ONLY
                </MenuItem>
            </Select>
        );

        return sourceColumn;
    };

    const renderEmailAddressColumn = (item, index) => {
        const rows = [...templateRows];
        const emailColumn = (
            <TextField
                key={"template-emails-" + item.TemplateId}
                type="text"
                value={item.EmailAddress}
                error={!!emailErrors[item.TemplateId]}
                helperText={emailErrors[item.TemplateId] ? "Invalid email(s) format" : ""}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    style: { fontSize: "16px" },
                    maxLength: 250,
                }}
                onChange={e => {
                    const email = removeBlockCharacters(e.target.value);
                    rows[index].EmailAddress = email;
                    setTemplateRows(rows);
                    validateEmail(item.TemplateId, email);
                }}
                onBlur={e => handleChange(item.TemplateId, "EmailAddress", e)}
            />
        );

        return emailColumn;
    };

    const handleChange = (TemplateId, attributeName, e) => {
        const value = e.target.value;
        let updatedValue;

        switch (attributeName) {
            case "EmailAddress":
                updatedValue = removeBlockCharacters(value);
                if (!validateEmail(TemplateId, updatedValue)) return;
                break;
            case "AdAccountIds":
                updatedValue = checkAdIds(value);
                break;
            default:
                updatedValue = removeBlockCharacters(value);
                break;
        }
        dispatch(updateAttribute("flowExportTemplateFields", TemplateId, attributeName, updatedValue));
    };

    const validateEmail = (TemplateId, email) => {
        const emailRegex = /^[^@\s]+@[^@\s]+\.(com|net|org|gov)$/i;
        const emails = email.split(";").map(e => e.trim());
        const invalidEmails = emails.filter(e => e && !emailRegex.test(e));
        const isValid = invalidEmails.length === 0;

        setEmailErrors(prevErrors => ({
            ...prevErrors,
            [TemplateId]: !isValid,
        }));

        return isValid;
    };

    const removeBlockCharacters = value => value.replace(/,/g, "");

    return (
        <>
            <div className="flow-edit-header">
                <div style={{ flexGrow: 1, position: "relative" }}>
                    <p>Driver File Fields</p>
                </div>
            </div>
            <Divider style={{ margin: "20px auto" }} />
            <Paper style={{ marginTop: "50px", width: "100%" }}>
                <div className="m-t-md">{renderTemplateTable()}</div>
            </Paper>
            {newMetaTemplate && (
                <div style={{ paddingTop: "5px", fontSize: "13px" }}>
                    * Notification Emails: This will allow Experian to notify the user when the file is ready. You can
                    add multiple emails separated by a semicolon.
                </div>
            )}
            <Divider style={{ margin: "40px auto 20px auto" }} />
        </>
    );
};

ExportTemplateFields.propTypes = {
    destinationId: PropTypes.number,
    flowExportTemplateFields: PropTypes.array,
    canEdit: PropTypes.bool,
};

export default ExportTemplateFields;
