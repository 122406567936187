import React, { useEffect, useState } from "react";
import highlightForSearch from "../../helpers/highlightForSearch";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { requestCompanies, setLoadingCompany } from "../../actions/userActions";
import breadcrumbs from "../../helpers/field";
import { IField } from "../../types/stores/fieldTypes";
import { IAppState } from "../../types/stores/index";
import FeatureGate from "../FeatureGate";
import TabGate from "../TabGate";

type Props = {
    field: IField;
    treeSearch: string | Array<string>;
    tabType?: number;
    showTabs?: boolean;
};

const makeGetDisplayLeafDescription = () =>
    createSelector(
        (_, props) => props.field && props.field.leaf_description,
        (_, props) => props.treeSearch,
        (leafDescription, treeSearch) => highlightForSearch(leafDescription, treeSearch)
    );

const getDisplayLeafDescription = makeGetDisplayLeafDescription();

const FieldTooltipContents: React.FC<Props> = (props: Props) => {
    // Ignoring because it is used in getDisplayLeafDescription
    //@ts-expect-error
    const { field, treeSearch, tabType, showTabs } = props;
    const dispatch = useDispatch();

    const fields = useSelector((state: IAppState) => state.fields);
    const displayLeafDescription = useSelector((state: IAppState) => getDisplayLeafDescription(state, props));
    const companies = useSelector((state: IAppState) => state.companies.byId);
    const loadingCompanies = useSelector((state: IAppState) => state.vars.loadingCompanies);
    const fieldRatesById = useSelector((state: IAppState) => state.fieldsByCompany.fieldRates);
    const taxmanFieldRatesById = useSelector((state: IAppState) => state.fieldsByCompany.taxmanFieldRates);
    const fieldDistributionCountsById = useSelector(
        (state: IAppState) => state.fieldsByCompany.fieldDistributionCounts
    );
    const [currentFieldRateCPM, setCurrentFieldRateCPM] = useState(0);
    const [currentFieldRatePOM, setCurrentFieldRatePOM] = useState(0);
    const [currentDistributionCount, setCurrentDistributionCount] = useState(0);

    const USDollar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const DCFprmat = new Intl.NumberFormat("en-US");

    // Intl.NumberFormat(language, {notation: "compact"}).format(number) //output - "234K"

    useEffect(() => {
        if (field) {
            let fieldRatetoUse = field.IsIngested
                ? taxmanFieldRatesById[field.id || 0]
                : fieldRatesById[field.UmbrellaFieldId || 0];
            if (fieldRatetoUse) {
                setCurrentFieldRateCPM(fieldRatetoUse.CPM);
                setCurrentFieldRatePOM(fieldRatetoUse.PercentOfMedia);
            }
            let distributionCounttoUse = fieldDistributionCountsById[field.fieldkey];
            if (distributionCounttoUse) {
                setCurrentDistributionCount(distributionCounttoUse.Fieldcount);
            }
        }
    }, [fieldRatesById, taxmanFieldRatesById, fieldDistributionCountsById, field]);

    //Get Companies for Tooltip Names
    useEffect(() => {
        if (!companies || (Object.keys(companies).length <= 0 && !loadingCompanies)) {
            dispatch(setLoadingCompany(true));
            dispatch(requestCompanies());
        }
    }, [dispatch, companies, loadingCompanies]);

    if (!field) {
        return (
            <span>
                <p>Information is not available.</p>
            </span>
        );
    }

    // const fieldRate = fieldRates.byId[field.fieldkey];
    const breadcrumb = (field: IField) => (field.isLeaf ? breadcrumbs(fields, field.workid!, true) : null);

    const cpm = currentFieldRateCPM ? USDollar.format(currentFieldRateCPM) : 0;
    const pom = currentFieldRatePOM ? currentFieldRatePOM + "%" : 0;
    const dc = currentDistributionCount ? DCFprmat.format(currentDistributionCount) : 0; // numeral(currentDistributionCount).format('0.0a')

    const companyString = (field: IField) => {
        if (field.companyid == 0) {
            return "Experian Data";
        }
        const company = companies[field.companyid];
        return company && company.CompanyName
            ? "Company: " + company.CompanyName
            : "No company tooltip information for this filter.";
    };

    return (
        <div>
            {field.leaf_description ? (
                <div>
                    <table>
                        <tr>
                            <td width={"60%"}>
                                <h4 style={{ textAlign: "left" }}>{field.text}</h4>
                            </td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            {(currentDistributionCount != 0 ||
                                currentFieldRateCPM != 0 ||
                                currentFieldRatePOM != 0) && <td width={"3%"}></td>}
                            {currentDistributionCount != 0 && (
                                <td width={"37%"}>
                                    <FeatureGate feature="display-distribution-counts">
                                        <div
                                            style={{
                                                textAlign: "left",
                                                alignContent: "left",
                                            }}
                                        >
                                            <b>Household&nbsp;Count:</b>
                                            <h6>{dc}</h6>
                                        </div>
                                    </FeatureGate>
                                </td>
                            )}
                            {(currentFieldRateCPM != 0 || currentFieldRatePOM != 0) && <td width={"3%"}></td>}
                            {currentFieldRateCPM != 0 && (
                                <td width={"27%"}>
                                    <FeatureGate feature={["display-field-rates", "field-tree-tabs"]}>
                                        <TabGate tabType={tabType} showTabs={showTabs}>
                                            <div
                                                style={{
                                                    textAlign: "left",
                                                    alignContent: "left",
                                                }}
                                            >
                                                <b>Price (CPM):</b>
                                                <h6>{cpm}</h6>
                                            </div>
                                        </TabGate>
                                    </FeatureGate>
                                </td>
                            )}

                            {currentFieldRatePOM != 0 && <td width={"3%"}></td>}
                            {currentFieldRatePOM != 0 && (
                                <td width={"27%"}>
                                    <FeatureGate feature={["display-field-rates", "field-tree-tabs"]}>
                                        <TabGate tabType={tabType} showTabs={showTabs}>
                                            <div
                                                style={{
                                                    textAlign: "left",
                                                    alignContent: "left",
                                                }}
                                            >
                                                <b>% of Media:</b>
                                                <h6>{pom}</h6>
                                            </div>
                                        </TabGate>
                                    </FeatureGate>
                                </td>
                            )}
                            <td width={"10%"}> </td>
                        </tr>
                    </table>

                    <h5>{breadcrumb(field)}</h5>
                    <h6>{companyString(field)}</h6>
                    <p>{displayLeafDescription}</p>
                </div>
            ) : (
                <div>
                    <h4>{field.text}</h4>
                    <h5>{breadcrumb(field)}</h5>
                    <h6>{companyString(field)}</h6>
                </div>
            )}
            {field.FieldLabels && (
                <div>
                    <span className="text-danger">
                        <strong>{field.FieldLabels}</strong>
                    </span>
                </div>
            )}
            {field.FieldSelectBy == "N" && (
                <div>
                    <span className="text-danger">
                        <strong>Field is hidden</strong>
                    </span>
                </div>
            )}
        </div>
    );
};

export default FieldTooltipContents;
