import React from "react";
import Notification from "./Notification";
import { Notification as NotificationType, DismissNotification } from "../../types/stores/notifications";

type Props = {
    notification: NotificationType;
    onDismiss: () => DismissNotification;
    timeout: number;
};

const NotifyTimer: React.FC<Props> = ({ notification, onDismiss }: Props) => (
    <Notification notification={notification} onDismiss={() => onDismiss()} />
);

export default NotifyTimer;
