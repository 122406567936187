import React from "react";
import clsx from "clsx";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import { DismissNotification } from "../../types/stores/notifications";

const variantIcon = {
    success: "check_circle",
    warning: "warning",
    error: "error",
    info: "info",
};

const userStyles = makeStyles(theme => ({
    success: {
        backgroundColor: "#43a047",
    },
    error: {
        backgroundColor: "#d32f2f",
    },
    info: {
        backgroundColor: "#1976d2",
    },
    warning: {
        backgroundColor: "#ffa000",
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: "flex",
        alignItems: "center",
    },
}));

type Props = {
    className?: string;
    onClose: () => DismissNotification;
    message: JSX.Element;
    variant: "error" | "info" | "success" | "warning";
};

type Classes = ClassNameMap<"success" | "icon" | "error" | "info" | "warning" | "iconVariant" | "message">;

function MySnackbarContentWrapper(props: Props) {
    const classes: Classes = userStyles();
    const { className, message, variant, onClose, ...other } = props;
    const icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className || "")}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <i className={clsx(classes.icon, classes.iconVariant, "material-icons")}>{icon}</i>
                    <span id="client-snackbar-message">{message}</span>
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <i className={`material-icons ${classes.icon}`}>close</i>
                </IconButton>,
            ]}
            {...other}
        />
    );
}

const VariantSnackbar: React.FC<Props> = ({ className, onClose, message, variant }: Props) => (
    <Snackbar
        open={true}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        autoHideDuration={2000}
        onClose={onClose}
    >
        <MySnackbarContentWrapper
            className={className}
            variant={variant || "info"}
            message={message}
            onClose={onClose}
        />
    </Snackbar>
);

export default VariantSnackbar;
