import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme, withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MaterialTable from "@material-table/core";
import MomentUtils from "@date-io/moment";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import NumericalTextField from "../../../material-components/Misc/NumericalTextField";
import { DeliveryType } from "../../../../enums/company";
import { updateAttribute, getUpdatedCPMandPOM } from "../../../../actions/flowActions";
import { requestCompanies, setLoadingCompany } from "../../../../actions/userActions";
import { parseDateToTimeZoneWithTime } from "../../../../helpers/parseDate";

// used to remove arrow icons from number text fields
// cant have those because we fail to update on click from 1 -> 0
// due to using both selects and text fields
const styles = {
    input: {
        "&[type=number]": {
            "-moz-appearance": "textfield",
        },
        "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
};

const ExpandLess = <i className="material-icons">expand_less</i>;
const ExpandMore = <i className="material-icons">expand_more</i>;
const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const ExportTradeDeskTemplateFields = ({
    tapadDeliveryTypeId,
    destinationId,
    flowExportTradeDeskTemplateFields,
    canEdit,
    classes,
}) => {
    const dispatch = useDispatch();
    const tableRef = useRef();

    const defaultExpansionType = "HOUSEHOLD";

    // state
    const [templateRows, setTemplateRows] = useState([]);
    const [deliveryType, setDeliveryType] = useState();
    const [isOpenGlobalAssignment, setIsOpenGlobalAssignment] = useState(false);
    const [globalExpansionType, setGlobalExpansionType] = useState(defaultExpansionType);
    const [globalPercentOfMediaCost, setGlobalPercentOfMediaCost] = useState(0);
    const [globalCostPerMillion, setGlobalCostPerMillion] = useState(0);
    const [globalExpirationDate, setGlobalExpirationDate] = useState();
    const [refreshTable, setRefreshTable] = useState(0);

    // props
    const companies = useSelector(state => state.companies.byId);
    const loadingCompanies = useSelector(state => state.vars.loadingCompanies);
    const flow = useSelector(state => state.flows.byId[state.selected.flow]);
    const timeZoneIana = useSelector(state => state.session.userSettings["time-zone-iana"]);
    const tapadDeliveryTypes = useSelector(state => state.vars.tapadDeliveryTypes);
    const enabledFeatures = useSelector(state => state.session.enabledFeatures);

    // Variables use to calcuate CPM and POM
    const flowItems = useSelector(state => state.flowItems.byId);
    const hasFeatureDisplayFieldRates = enabledFeatures.includes("display-field-rates");

    const renderTemplateRows = () => {
        let rows = [];

        // Set default parentElementId and ParentElementName
        let defaultParentElementId = "";
        let defaultParentElementName = "";

        if (deliveryType) {
            if (DeliveryType.ThirdPartyCustom == deliveryType.DeliveryType) {
                defaultParentElementId = deliveryType.ParentElementId;
                defaultParentElementName = deliveryType.ParentElementName;
            }
        }

        if (flowExportTradeDeskTemplateFields != null) {
            for (const template of flowExportTradeDeskTemplateFields) {
                // if the date is null, not showing a default date because it makes the user think it's good
                // only happens when saving an old flow with null ExpirationDate
                const formattedRunDate = template.ExpirationDate
                    ? new Date(parseDateToTimeZoneWithTime(template.ExpirationDate, timeZoneIana))
                    : null;

                if (deliveryType && DeliveryType.ThirdPartyCustom == deliveryType.DeliveryType) {
                    if (template.ParentElementId == null) {
                        dispatch(
                            updateAttribute(
                                "flowExportTradeDeskTemplateFields",
                                template.FlowExportTemplateId,
                                "ParentElementId",
                                defaultParentElementId
                            )
                        );
                    }

                    if (template.ParentElementName == null) {
                        dispatch(
                            updateAttribute(
                                "flowExportTradeDeskTemplateFields",
                                template.FlowExportTemplateId,
                                "ParentElementName",
                                defaultParentElementName
                            )
                        );
                    }
                }

                // Default Expansion Type
                if (!template.ExpansionType || template.ExpansionType == "") {
                    dispatch(
                        updateAttribute(
                            "flowExportTradeDeskTemplateFields",
                            template.FlowExportTemplateId,
                            "ExpansionType",
                            defaultExpansionType
                        )
                    );
                }

                if (deliveryType && DeliveryType.ThirdPartyCustom == deliveryType.DeliveryType) {
                    if (template.RateType != "Hybrid") {
                        handleRateTypeChange(template.FlowExportTemplateId, "Hybrid");
                    }
                }

                rows.push({
                    FlowExportTemplateId: template.FlowExportTemplateId,
                    ParentFlowItemId: template.ParentFlowItemId,
                    AudienceName: template.AudienceName || "",
                    Description: template.Description || "",
                    ExpansionType: template.ExpansionType || defaultExpansionType,
                    RateType: template.RateType || "CPM",
                    CostPerMillion: template.CostPerMillion || 0,
                    PercentOfMediaCost: template.PercentOfMediaCost || 0,
                    TTL: template.TTL || 0,
                    ExpirationDate: formattedRunDate,
                    ProviderElementId: template.ProviderElementId || "",
                    ParentElementId:
                        template.ParentElementId != null ? template.ParentElementId : defaultParentElementId,
                    ParentElementName:
                        template.ParentElementName != null ? template.ParentElementName : defaultParentElementName,
                    IsAutoGeneratedCPM: template.IsAutoGeneratedCPM,
                    IsAutoGeneratedPOM: template.IsAutoGeneratedPOM,
                });
            }
        }

        setTemplateRows(rows);
        setRefreshTable(refreshTable + 1);
    };

    // effects
    useEffect(() => {
        renderTemplateRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [destinationId, timeZoneIana, deliveryType, dispatch]);

    // effects

    useEffect(() => {
        if (!companies || (Object.keys(companies).length <= 0 && !loadingCompanies)) {
            dispatch(setLoadingCompany(true));
            dispatch(requestCompanies());
        }
    }, [dispatch, companies, loadingCompanies]);

    useEffect(() => {
        const thisDeliveryType = tapadDeliveryTypes.find(x => x.TapadDeliveryTypeId == tapadDeliveryTypeId);

        if (deliveryType && thisDeliveryType.TapadDeliveryTypeId != deliveryType.TapadDeliveryTypeId) {
            // addMissingFlowOfferMerges
            for (let i = 0; i < flowExportTradeDeskTemplateFields.length; i++) {
                const flowItemsArray = Object.values(flowItems);
                const FlowItem = flowItemsArray.find(
                    x => x.FlowItemId == flowExportTradeDeskTemplateFields[i].ParentFlowItemId
                );

                const newCPMValue = dispatch(
                    getUpdatedCPMandPOM(
                        FlowItem,
                        flowExportTradeDeskTemplateFields[i].FlowItemId,
                        flowExportTradeDeskTemplateFields[i].ParentFlowItemId,
                        hasFeatureDisplayFieldRates,
                        1
                    )
                );
                flowExportTradeDeskTemplateFields[i].CostPerMillion = newCPMValue;
                const newPOMValue = dispatch(
                    getUpdatedCPMandPOM(
                        FlowItem,
                        flowExportTradeDeskTemplateFields[i].FlowItemId,
                        flowExportTradeDeskTemplateFields[i].ParentFlowItemId,
                        hasFeatureDisplayFieldRates,
                        2
                    )
                );
                flowExportTradeDeskTemplateFields[i].PercentOfMediaCost = newPOMValue;
                flowExportTradeDeskTemplateFields[i].IsAutoGeneratedCPM = true;
                flowExportTradeDeskTemplateFields[i].IsAutoGeneratedPOM = true;
            }
            setTemplateRows(flowExportTradeDeskTemplateFields);
            renderTemplateRows();
            setRefreshTable(refreshTable + 1);
        }
        if (thisDeliveryType && flowExportTradeDeskTemplateFields.length > 0) {
            setDeliveryType(thisDeliveryType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tapadDeliveryTypeId, flowExportTradeDeskTemplateFields]);

    const selectAudienceName = cellInfo => {
        const rows = [...templateRows];
        const column = (
            <TextField
                key={cellInfo.tableData.id + "-audience-name-" + cellInfo.FlowExportTemplateId}
                type="text"
                defaultValue={cellInfo.AudienceName}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    style: { fontSize: "16px" },
                    maxLength: 100,
                }}
                onChange={e => {
                    rows[cellInfo.tableData.id].AudienceName = e.target.value;
                    setTemplateRows(rows);
                }}
                onBlur={e => handleChange(cellInfo.FlowExportTemplateId, "AudienceName", e)}
                fullWidth
            />
        );
        return column;
    };

    const selectDescription = cellInfo => {
        const rows = [...templateRows];
        const column = (
            <TextField
                key={cellInfo.tableData.id + "-template-description-" + cellInfo.FlowExportTemplateId}
                type="text"
                defaultValue={cellInfo.Description}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    style: { fontSize: "16px" },
                }}
                onChange={e => {
                    rows[cellInfo.tableData.id].Description = e.target.value;
                    setTemplateRows(rows);
                }}
                onBlur={e => handleChange(cellInfo.FlowExportTemplateId, "Description", e)}
                fullWidth
            />
        );
        return column;
    };

    const selectExpansionType = cellInfo => {
        const rows = [...templateRows];
        const column = (
            <Select
                key={cellInfo.tableData.id + "expansion-type" + cellInfo.FlowExportTemplateId}
                style={{ fontSize: "14px", width: "100%" }}
                value={cellInfo.ExpansionType}
                onChange={e => {
                    rows[cellInfo.tableData.id].ExpansionType = e.target.value;
                    setTemplateRows(rows);
                    handleExpansionTypeChange(cellInfo.FlowExportTemplateId, e);
                }}
                disabled={!canEdit || (flow && flow.IsLocked)}
                displayEmpty
            >
                <MenuItem key={2} value={"HOUSEHOLD"}>
                    Household
                </MenuItem>
            </Select>
        );
        return column;
    };

    const selectExpirationDate = cellInfo => {
        const rows = [...templateRows];
        const column = (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                    key={cellInfo.tableData.id + "-expiration-date-" + cellInfo.FlowExportTemplateId}
                    format={"MMM DD, YYYY HH:mm"}
                    value={cellInfo.ExpirationDate}
                    disabled={!canEdit || (flow && flow.IsLocked)}
                    onChange={e => {
                        const newDateTime = e.utc().format();
                        rows[cellInfo.tableData.id].ExpirationDate = newDateTime;
                        setTemplateRows(rows);
                        handleDateTimeChange(cellInfo.FlowExportTemplateId, "ExpirationDate", newDateTime);
                        setRefreshTable(refreshTable + 1);
                    }}
                />
            </MuiPickersUtilsProvider>
        );
        return column;
    };

    const selectRateType = cellInfo => {
        if (DeliveryType.ThirdPartyCustom == deliveryType?.DeliveryType) {
            return "Hybrid";
        }

        const rows = [...templateRows];
        const column = (
            <Select
                key={cellInfo.tableData.id + "-rate-type-" + cellInfo.FlowExportTemplateId}
                style={{ fontSize: "14px", width: "100%" }}
                value={cellInfo.RateType}
                onChange={e => {
                    rows[cellInfo.tableData.id].RateType = e.target.value;
                    setTemplateRows(rows);
                    handleRateTypeChange(cellInfo.FlowExportTemplateId, e.target.value);
                }}
                disabled={!canEdit || (flow && flow.IsLocked)}
                displayEmpty
            >
                <MenuItem key={1} value={"CPM"}>
                    CPM
                </MenuItem>
                <MenuItem key={2} value={"PercentOfMediaCost"}>
                    % of Media Cost
                </MenuItem>
                <MenuItem key={3} value={"Hybrid"}>
                    Hybrid
                </MenuItem>
            </Select>
        );
        return column;
    };

    const getCellStyleCPM = (cellInfo, rowData) => {
        // const rows = [...templateRows];
        // var myRow = rows[cellInfo.FlowExportTemplateId];
        let isAutoGenerated = rowData.IsAutoGeneratedCPM;
        let cellstyleJSON;
        if (isAutoGenerated && hasFeatureDisplayFieldRates) {
            cellstyleJSON = {
                backgroundColor: "#87CEEB",
                color: "#OOOO",
            };
        } else {
            cellstyleJSON = {
                backgroundColor: "#FFFF",
                color: "#OOOO",
            };
        }

        return cellstyleJSON;
    };
    const getCellStylePOM = (cellInfo, rowData) => {
        // const rows = [...templateRows];
        // var myRow = rows[cellInfo.FlowExportTemplateId];
        let isAutoGenerated = rowData.IsAutoGeneratedPOM;
        let cellstyleJSON;
        if (isAutoGenerated && hasFeatureDisplayFieldRates) {
            cellstyleJSON = {
                backgroundColor: "#87CEEB",
                color: "#OOOO",
            };
        } else {
            cellstyleJSON = {
                backgroundColor: "#FFFF",
                color: "#OOOO",
            };
        }

        return cellstyleJSON;
    };

    const selectCostPerMillion = cellInfo => {
        const rows = [...templateRows];
        const column = (
            <NumericalTextField
                key={cellInfo.tableData.id + "-CostPerMillion-" + cellInfo.FlowExportTemplateId}
                classes={classes}
                value={USDollar.format(cellInfo.CostPerMillion)}
                disabled={!canEdit || (flow && flow.IsLocked)}
                numberType="currency"
                onChange={e => {
                    rows[cellInfo.tableData.id].CostPerMillion = e.target.value.replace(/[^0-9.]/g, "");
                    rows[cellInfo.tableData.id].IsAutoGeneratedCPM = false;
                    setTemplateRows(rows);
                }}
                onBlur={e => {
                    handlePositiveValueChange(
                        cellInfo.tableData.id,
                        cellInfo.FlowExportTemplateId,
                        "CostPerMillion",
                        e
                    );
                }}
            />
        );
        return column;
    };

    const selectPercentOfMediaCost = cellInfo => {
        const rows = [...templateRows];
        const column = (
            <NumericalTextField
                key={cellInfo.tableData.id + "-PercentOfMediaCost-" + cellInfo.FlowExportTemplateId}
                classes={classes}
                value={cellInfo.PercentOfMediaCost + "%"}
                disabled={!canEdit || (flow && flow.IsLocked)}
                numberType="percent"
                onChange={e => {
                    rows[cellInfo.tableData.id].PercentOfMediaCost = e.target.value.replace(/[^0-9.]/g, "");
                    rows[cellInfo.tableData.id].IsAutoGeneratedPOM = false;
                    setTemplateRows(rows);
                }}
                onBlur={e =>
                    handlePositiveValueChange(
                        cellInfo.tableData.id,
                        cellInfo.FlowExportTemplateId,
                        "PercentOfMediaCost",
                        e
                    )
                }
            />
        );
        return column;
    };

    const selectParentElementId = cellInfo => {
        const rows = [...templateRows];
        const column = (
            <TextField
                key={cellInfo.tableData.id + "-template-parent-element-id-" + cellInfo.FlowExportTemplateId}
                type="text"
                defaultValue={cellInfo.ParentElementId}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    style: { fontSize: "16px" },
                }}
                onChange={e => {
                    rows[cellInfo.tableData.id].ParentElementId = e.target.value;
                    setTemplateRows(rows);
                }}
                onBlur={e => handleChange(cellInfo.FlowExportTemplateId, "ParentElementId", e)}
            />
        );
        return column;
    };

    const selectParentElementName = cellInfo => {
        const rows = [...templateRows];
        const column = (
            <TextField
                key={cellInfo.tableData.id + "-template-parent-element-name-" + cellInfo.FlowExportTemplateId}
                type="text"
                defaultValue={cellInfo.ParentElementName}
                disabled={!canEdit || (flow && flow.IsLocked)}
                inputProps={{
                    style: { fontSize: "16px" },
                }}
                onChange={e => {
                    rows[cellInfo.tableData.id].ParentElementName = e.target.value;
                    setTemplateRows(rows);
                }}
                onBlur={e => handleChange(cellInfo.FlowExportTemplateId, "ParentElementName", e)}
            />
        );
        return column;
    };

    const handleChange = (TemplateId, attributeName, e) =>
        dispatch(updateAttribute("flowExportTradeDeskTemplateFields", TemplateId, attributeName, e.target.value));

    const handleDateTimeChange = (TemplateId, attributeName, newDateTime) => {
        dispatch(updateAttribute("flowExportTradeDeskTemplateFields", TemplateId, attributeName, newDateTime));
    };

    const handleGlobalDateTimeChange = newDateTime => {
        const utcDateTime = newDateTime.utc().format();
        setGlobalExpirationDate(utcDateTime);
    };

    const handlePositiveValueChange = (rowID, TemplateId, attributeName, e) => {
        const rows = [...templateRows];
        const templateIneed = flowExportTradeDeskTemplateFields.find(x => x.FlowExportTemplateId == TemplateId);
        let positiveValue = 0;
        if (e.target.value != null) {
            let thisVal = e.target.value.replace(/[^0-9.]/g, "");

            if (thisVal > 0) {
                positiveValue = thisVal;
            }
        }
        if (attributeName == "CostPerMillion" && templateIneed.CostPerMillion == positiveValue) {
            return;
        }
        if (attributeName == "PercentOfMediaCost" && templateIneed.PercentOfMediaCost == positiveValue) {
            return;
        }
        //tapadDeliveryTypes.find(x => x.TapadDeliveryTypeId == tapadDeliveryTypeId);
        if (attributeName == "CostPerMillion") {
            dispatch(
                updateAttribute("flowExportTradeDeskTemplateFields", TemplateId, "IsAutoGeneratedCPM", false, true)
            );
            rows[rowID].IsAutoGeneratedCPM = false;
        } else {
            dispatch(
                updateAttribute("flowExportTradeDeskTemplateFields", TemplateId, "IsAutoGeneratedPOM", false, true)
            );
            rows[rowID].IsAutoGeneratedPOM = false;
        }
        dispatch(updateAttribute("flowExportTradeDeskTemplateFields", TemplateId, attributeName, positiveValue, true));
        setTemplateRows(rows);
        //  setRefreshTable(refreshTable + 1);
    };

    const handleGlobalPositiveValueChange = (attributeName, e) => {
        let positiveValue = 0;
        if (e.target.value != null) {
            let thisVal = e.target.value.replace(/[^0-9.]/g, "");

            if (thisVal > 0) {
                positiveValue = thisVal;
            }
        }

        if (attributeName == "PercentOfMediaCost") {
            setGlobalPercentOfMediaCost(positiveValue);
        } else {
            setGlobalCostPerMillion(positiveValue);
        }
    };

    const handleRateTypeChange = (TemplateId, value) =>
        dispatch(updateAttribute("flowExportTradeDeskTemplateFields", TemplateId, "RateType", value));

    const handleExpansionTypeChange = (TemplateId, e) =>
        dispatch(updateAttribute("flowExportTradeDeskTemplateFields", TemplateId, "ExpansionType", e.target.value));

    const assignGlobalValues = () => {
        if (flowExportTradeDeskTemplateFields != null) {
            for (const template of flowExportTradeDeskTemplateFields) {
                // Set Expansion Type
                dispatch(
                    updateAttribute(
                        "flowExportTradeDeskTemplateFields",
                        template.FlowExportTemplateId,
                        "ExpansionType",
                        globalExpansionType
                    )
                );

                // Set Expiration Date
                dispatch(
                    updateAttribute(
                        "flowExportTradeDeskTemplateFields",
                        template.FlowExportTemplateId,
                        "ExpirationDate",
                        globalExpirationDate
                    )
                );

                if (DeliveryType.ThirdPartyCustom == deliveryType?.DeliveryType) {
                    // Set Percent Of Media Cost
                    dispatch(
                        updateAttribute(
                            "flowExportTradeDeskTemplateFields",
                            template.FlowExportTemplateId,
                            "PercentOfMediaCost",
                            globalPercentOfMediaCost
                        )
                    );

                    // Set Cost Per Million
                    dispatch(
                        updateAttribute(
                            "flowExportTradeDeskTemplateFields",
                            template.FlowExportTemplateId,
                            "CostPerMillion",
                            globalCostPerMillion
                        )
                    );
                    // Set IsAutoGenerated
                    dispatch(
                        updateAttribute(
                            "flowExportTradeDeskTemplateFields",
                            template.FlowExportTemplateId,
                            "IsAutoGeneratedCPM",
                            false
                        )
                    );
                    dispatch(
                        updateAttribute(
                            "flowExportTradeDeskTemplateFields",
                            template.FlowExportTemplateId,
                            "IsAutoGeneratedPOM",
                            false
                        )
                    );
                }
            }

            const rows = [...templateRows];
            for (let row of rows) {
                row.ExpansionType = globalExpansionType;
                row.ExpirationDate = globalExpirationDate;

                if (DeliveryType.ThirdPartyCustom == deliveryType?.DeliveryType) {
                    row.PercentOfMediaCost = globalPercentOfMediaCost;
                    row.CostPerMillion = globalCostPerMillion;
                    row.IsAutoGeneratedCPM = false;
                    row.IsAutoGeneratedPOM = false;
                }
            }

            setTemplateRows(rows);
            setRefreshTable(refreshTable + 1);
        }
    };

    const getColumns = useMemo(() => {
        const thisDeliveryType = tapadDeliveryTypes.find(x => x.TapadDeliveryTypeId == tapadDeliveryTypeId);
        let columnData = [
            {
                title: "Audience Name",
                field: "AudienceName",
                render: rowData => selectAudienceName(rowData),
                headerStyle: {
                    backgroundColor: "#0075bc",
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: "14px",
                },

                width: 150,
                minWidth: 150,
            },
            {
                title: "Description",
                field: "Description",
                render: rowData => selectDescription(rowData),
                headerStyle: {
                    backgroundColor: "#0075bc",
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: "14px",
                },

                width: 150,
                minWidth: 150,
            },
            {
                title: "Expansion Type",
                field: "ExpansionType",
                render: rowData => selectExpansionType(rowData),
                headerStyle: {
                    backgroundColor: "#0075bc",
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: "14px",
                },
                width: 150,
                minWidth: 150,
            },
            {
                title: "Expiration Date",
                field: "ExpirationDate",
                render: rowData => selectExpirationDate(rowData),
                headerStyle: {
                    backgroundColor: "#0075bc",
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: "14px",
                },
                width: 150,
                minWidth: 150,
            },
        ];

        if (DeliveryType.ThirdPartyCustom == thisDeliveryType?.DeliveryType) {
            columnData.push({
                title: "Rate Type",
                field: "RateType",
                render: rowData => selectRateType(rowData),
                headerStyle: {
                    backgroundColor: "#0075bc",
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: "14px",
                },
                width: 150,
                minWidth: 150,
            });

            columnData.push({
                title: "CPM ($)",
                field: "CostPerMillion",
                cellStyle: (cellValue, rowData) => getCellStyleCPM(cellValue, rowData),
                render: rowData => selectCostPerMillion(rowData),
                headerStyle: {
                    backgroundColor: "#0075bc",
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: "14px",
                },
                width: 150,
                minWidth: 150,
            });

            columnData.push({
                title: "% of Media Cost",
                field: "PercentOfMediaCost",
                cellStyle: (cellValue, rowData) => getCellStylePOM(cellValue, rowData),
                render: rowData => selectPercentOfMediaCost(rowData),
                headerStyle: {
                    backgroundColor: "#0075bc",
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: "14px",
                },
                width: 150,
                minWidth: 150,
            });

            columnData.push({
                title: "Parent Element Id",
                field: "ParentElementId",
                render: rowData => selectParentElementId(rowData),
                headerStyle: {
                    backgroundColor: "#0075bc",
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: "14px",
                },
                width: 150,
                minWidth: 150,
            });

            columnData.push({
                title: "Parent Element Name",
                field: "ParentElementName",
                render: rowData => selectParentElementName(rowData),
                headerStyle: {
                    backgroundColor: "#0075bc",
                    color: "#fff",
                    fontWeight: 400,
                    fontSize: "14px",
                },
                width: 150,
                minWidth: 150,
            });
        }

        return columnData;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshTable, deliveryType, canEdit, flow, tapadDeliveryTypeId, tapadDeliveryTypes]);

    const theme = createTheme({
        overrides: {
            MuiTable: {
                root: {
                    marginTop: "0px !important",
                },
            },
            MuiTableCell: {
                root: {
                    fontSize: "14px",
                },
            },
        },
    });

    return (
        <>
            <div className="flow-edit-header" style={{ flexWrap: "wrap" }}>
                <div style={{ display: "flex", flexGrow: 1 }}>
                    <p>Driver File Fields</p>
                </div>
                <div className="flow-edit-header-actions" style={{ display: "flex", marginLeft: "20px" }}>
                    <div style={{ display: "flex", marginLeft: "40px" }}></div>
                </div>
            </div>
            <Divider style={{ margin: "20px auto" }} />
            <ThemeProvider theme={theme}>
                <ListItem
                    key="button-global-fields-assignment"
                    style={{ display: "flex", justifyContent: "center" }}
                    button
                    onClick={() => setIsOpenGlobalAssignment(!isOpenGlobalAssignment)}
                >
                    <span style={{ display: "flex", fontSize: "16px" }}>Global Fields Assignment</span>
                    <span style={{ display: "flex", position: "absolute", right: "0", fontSize: "16px" }}>
                        {isOpenGlobalAssignment ? ExpandLess : ExpandMore}
                    </span>
                </ListItem>
                <Collapse
                    key="container-global-fields-assignment"
                    in={isOpenGlobalAssignment}
                    timeout="auto"
                    unmountOnExit
                >
                    <div className="row" style={{ height: "100%" }}>
                        <div className="col col-sm-4 col-md-3">
                            <FormControl fullWidth style={{ margin: "10px 0 0" }}>
                                <InputLabel shrink style={{ fontSize: 14 }}>
                                    Expansion Type
                                </InputLabel>
                                <Select
                                    value={globalExpansionType}
                                    onChange={e => setGlobalExpansionType(e.target.value)}
                                    disabled={!canEdit || (flow && flow.IsLocked)}
                                    displayEmpty
                                >
                                    <MenuItem key={2} value={"HOUSEHOLD"}>
                                        Household
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {DeliveryType.ThirdPartyCustom == deliveryType?.DeliveryType ? (
                                <NumericalTextField
                                    label="% of Media Spend"
                                    numberType="percent"
                                    style={{ fontSize: "14px", width: "100%", margin: "10px 0 0" }}
                                    value={globalPercentOfMediaCost + "%"}
                                    disabled={!canEdit || (flow && flow.IsLocked)}
                                    onBlur={e => handleGlobalPositiveValueChange("PercentOfMediaCost", e)}
                                />
                            ) : null}
                        </div>
                        <div className="col col-sm-4 col-md-3">
                            {DeliveryType.ThirdPartyCustom == deliveryType?.DeliveryType ? (
                                <NumericalTextField
                                    label="CPM"
                                    numberType="currency"
                                    style={{ fontSize: "14px", width: "100%", margin: "10px 0 0" }}
                                    value={USDollar.format(globalCostPerMillion)}
                                    disabled={!canEdit || (flow && flow.IsLocked)}
                                    onBlur={e => handleGlobalPositiveValueChange("CostPerMillion", e)}
                                />
                            ) : null}
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    label="Expiration Date"
                                    style={{ fontSize: "14px", width: "100%", margin: "10px 0 0" }}
                                    format={"MMM DD, YYYY HH:mm"}
                                    value={globalExpirationDate}
                                    onChange={e => handleGlobalDateTimeChange(e)}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col col-sm-4 col-md-6" style={{ height: "100%" }}>
                            <Button
                                color="primary"
                                variant="contained"
                                style={{ position: "absolute", bottom: "0" }}
                                onClick={assignGlobalValues}
                            >
                                Assign
                            </Button>
                        </div>
                    </div>
                </Collapse>
                <MaterialTable
                    tableRef={tableRef}
                    data={templateRows}
                    columns={getColumns}
                    // onBlur={setRefreshTable(refreshTable + 1)}
                    options={{
                        showTitle: false,
                        toolbar: false,
                        filtering: false,
                        grouping: false,
                        search: false,
                        sorting: true,
                        paging: false,
                        columnResizable: true,
                        tableLayout: "fixed",
                        headerStyle: { fontSize: 14, position: "sticky" },
                        maxBodyHeight: 400,
                        rowStyle: {
                            fontSize: "14px",
                            padding: "2px",
                        },
                    }}
                    style={{
                        margin: "20px 0px",
                    }}
                />
            </ThemeProvider>
            <div id="templateFieldsTable" style={{ width: "100%", overflowX: "auto" }}></div>
            <Divider style={{ margin: "40px auto 20px auto" }} />
        </>
    );
};

ExportTradeDeskTemplateFields.propTypes = {
    tapadDeliveryTypeId: PropTypes.number,
    destinationId: PropTypes.number,
    flowExportTradeDeskTemplateFields: PropTypes.array,
    canEdit: PropTypes.bool,
    classes: PropTypes.any,
};

export default withStyles(styles)(ExportTradeDeskTemplateFields);
