import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowExportsForSelectedFlow, getFlowExportsArray } from "./flowExports";
import type { FlowExportTradeDeskTemplateFields, FlowErrorsByItemId, FlowExport } from "../types/flowTypes";
import { DeliveryType } from "../enums/company";

const myGenericReducer = makeReducerFor("FLOW_EXPORT_TRADE_DESK_TEMPLATE_FIELDS", "FlowExportTemplateId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { DeploySettings } from "../types/stores/companyTable";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export const getFlowExportTradeDeskTemplateFieldsArray = createSelector(
    state => state.flowExportTradeDeskTemplateFields.byId,
    (flowExportTradeDeskTemplateFieldsById: {|
        [number]: FlowExportTradeDeskTemplateFields,
    |}): Array<FlowExportTradeDeskTemplateFields> => {
        const r: Array<FlowExportTradeDeskTemplateFields> = Object.values(flowExportTradeDeskTemplateFieldsById);
        return r;
    }
);

export type FlowExportTradeDeskTemplateFieldsByItemId = {
    [number]: Array<FlowExportTradeDeskTemplateFields>,
};

export const getFlowExportTradeDeskTemplateFieldsByFlowItemId = createSelector(
    state => getFlowExportTradeDeskTemplateFieldsArray(state),
    (
        flowExportTradeDeskTemplateFields: Array<FlowExportTradeDeskTemplateFields>
    ): FlowExportTradeDeskTemplateFieldsByItemId =>
        flowExportTradeDeskTemplateFields.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowExportTradeDeskTemplateFieldsForSelectedFlow = createSelector(
    state => getFlowExportsForSelectedFlow(state),
    state => getFlowExportTradeDeskTemplateFieldsByFlowItemId(state),
    state => state.vars.destinations,
    (
        flowExports: Array<FlowExport>,
        flowExportTradeDeskTemplateFieldsByItemId: FlowExportTradeDeskTemplateFieldsByItemId,
        destinations: Array<any>
    ): Array<FlowExportTradeDeskTemplateFields> => {
        let result = [];

        flowExports = flowExports.filter(x => {
            const thisDestination = destinations.find(y => y.PartnerAccessId == x.DestinationId);
            if (thisDestination && thisDestination.DeploySetting == DeploySettings.DeployTradedeskTemplate) {
                return x;
            }
        });

        const itemIds = flowExports.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const templateFields = flowExportTradeDeskTemplateFieldsByItemId[itemId];
            if (templateFields != null) {
                result = result.concat(templateFields);
            }
        }
        return result;
    }
);

const templateFieldsToErrorsById = (
    flowExports: Array<FlowExport>,
    flowExportTradeDeskTemplateFields: Array<FlowExportTradeDeskTemplateFields>,
    destinations: Array<any>,
    tapadDeliveryTypes: Array<any>
): FlowErrorsByItemId => {
    const errorsById = {};
    for (const flowExport of flowExports) {
        errorsById[flowExport.FlowItemId] = validateExportTradeDeskTemplateFields(
            flowExport,
            flowExportTradeDeskTemplateFields,
            destinations,
            tapadDeliveryTypes
        );
    }
    return errorsById;
};

export const getExportTradeDeskTemplateErrorsForSelectedFlow = createSelector(
    state => getFlowExportsForSelectedFlow(state),
    state => getFlowExportTradeDeskTemplateFieldsForSelectedFlow(state),
    state => state.vars.destinations,
    state => state.vars.tapadDeliveryTypes,
    templateFieldsToErrorsById
);

export const getExportTradeDeskTemplateErrorsForAllFlows = createSelector(
    state => getFlowExportsArray(state),
    state => getFlowExportTradeDeskTemplateFieldsArray(state),
    state => state.vars.destinations,
    templateFieldsToErrorsById
);

export const validateExportTradeDeskTemplateFields = (
    flowExport: FlowExport,
    flowExportTradeDeskTemplateFields: Array<FlowExportTradeDeskTemplateFields>,
    destinations: Array<any>,
    tapadDeliveryTypes: Array<any>
) => {
    const errors = [];

    if (!destinations || (destinations && destinations.length == 0)) {
        return errors;
    }

    const ExportTradeDeskTemplateFields = flowExportTradeDeskTemplateFields.filter(
        x => x.FlowItemId == flowExport.FlowItemId
    );
    const destination = destinations.find(x => x.PartnerAccessId == flowExport.DestinationId);

    // No destination selected
    if (!destination || !destination.DeploySetting == DeploySettings.DeployTradedeskTemplate) {
        return errors;
    }

    // Delivery types dropdown empty
    if (!tapadDeliveryTypes || tapadDeliveryTypes.length == 0) {
        return errors;
    }

    // Missing data
    if (
        ExportTradeDeskTemplateFields.length == 0 &&
        destination.DeploySetting == DeploySettings.DeployTradedeskTemplate
    ) {
        errors.push("Driver File Fields missing.");
        return errors;
    }

    const thisDeliveryType = tapadDeliveryTypes.find(x => x.TapadDeliveryTypeId == flowExport.DeliveryTypeId);
    if (!thisDeliveryType) {
        return errors;
    }

    const deliveryType = thisDeliveryType.DeliveryType;

    // Validations
    const noAudienceName = ExportTradeDeskTemplateFields.filter(x => x.AudienceName == null || x.AudienceName == "");
    const audienceNameLength = ExportTradeDeskTemplateFields.filter(
        x => x.AudienceName != null && x.AudienceName.length > 100
    );
    const noDescription = ExportTradeDeskTemplateFields.filter(x => x.Description == null || x.Description == "");
    const noExpansionType = ExportTradeDeskTemplateFields.filter(
        x => x.ExpansionType == null || x.ExpansionType == "" || x.ExpansionType.toUpperCase() != "HOUSEHOLD"
    );
    const noExpiratonDate = ExportTradeDeskTemplateFields.filter(x => !x.ExpirationDate);
    const hasExpiratonDate = ExportTradeDeskTemplateFields.filter(x => x.ExpirationDate);

    if (noAudienceName.length > 0) {
        errors.push("All Driver File Audience Name must be populated.");
    }

    if (noDescription.length > 0) {
        errors.push("All Driver File Description must be populated.");
    }

    if (noExpansionType.length > 0) {
        errors.push("All Driver File Expansion Type must be populated.");
    }

    if (noExpiratonDate.length > 0) {
        errors.push("All Driver File Expiration Dates must be populated.");
    }

    if (audienceNameLength.length > 0) {
        errors.push(
            "One or More Audience Names exceed 100 characters - please edit the Audience Name to be less than 100 characters."
        );
    }

    if (hasExpiratonDate.length > 0) {
        let invalidDate = false;
        const currDate = new Date();
        hasExpiratonDate.forEach(x => {
            const formateDate = new Date(x.ExpirationDate);
            if (formateDate < currDate) {
                invalidDate = true;
            }
        });

        if (invalidDate) {
            errors.push("Expiration dates may not be in the past. Check with sales on Contract Expiration Date.");
        }
    }

    if (DeliveryType.ThirdPartyCustom == deliveryType) {
        // Rate Type
        const noRateType = ExportTradeDeskTemplateFields.filter(x => x.RateType == null || x.RateType == "");
        if (noRateType.length > 0) {
            errors.push("All Driver File Rate Type must be populated.");
        }

        // Cost Per Million
        const anyCostPerMillion = ExportTradeDeskTemplateFields.filter(
            x => x.CostPerMillion == 0 && x.RateType == "CPM"
        );
        if (anyCostPerMillion.length > 0) {
            errors.push("Driver File: If 'CPM rate type' is selected, audience must have a Cost Per Million value.");
        }

        // CPM No Pct Media
        const anyCPMMedia = ExportTradeDeskTemplateFields.filter(
            x => x.RateType == "CPM" && parseFloat(x.PercentOfMediaCost) > 0
        );

        if (anyCPMMedia.length > 0) {
            errors.push("Driver File: If 'CPM rate type' is selected, audience cannot have a % of Media Cost value.");
        }

        // Percent of Media Cost
        const anyPercentOfMediaCost = ExportTradeDeskTemplateFields.filter(
            x => x.PercentOfMediaCost == 0 && x.RateType == "PercentOfMediaCost"
        );
        if (anyPercentOfMediaCost.length > 0) {
            errors.push(
                "Driver File: If '% of Media Cost rate type' is selected, audience must % of Media Cost value."
            );
        }

        // Pct Media No CPM
        const anyPctMediaCPM = ExportTradeDeskTemplateFields.filter(
            x => x.RateType == "PercentOfMediaCost" && parseFloat(x.CostPerMillion) > 0
        );
        if (anyPctMediaCPM.length > 0) {
            errors.push(
                "Driver File: If '% of Media Cost rate type' is selected, audience cannot have a Cost Per Million value."
            );
        }

        // Hybrid
        const anyHybrid = ExportTradeDeskTemplateFields.filter(
            x => (x.CostPerMillion == 0 || x.PercentOfMediaCost == 0) && x.RateType == "Hybrid"
        );
        if (anyHybrid.length > 0) {
            errors.push("CPM and Percent of Media Cost cannot be zero.");
        }

        // Parent Element Id
        const noParentElementId = ExportTradeDeskTemplateFields.filter(
            x => x.ParentElementId == null || x.ParentElementId == ""
        );
        if (noParentElementId.length > 0) {
            errors.push("All Driver File Parent Element Id must be populated.");
        }

        // Parent Element Name
        const noParentElementName = ExportTradeDeskTemplateFields.filter(
            x => x.ParentElementName == null || x.ParentElementName == ""
        );
        if (noParentElementName.length > 0) {
            errors.push("All Driver File Parent Element Name must be populated.");
        }

        // const noProviderElementId = ExportTradeDeskTemplateFields.filter(
        //     x => x.ProviderElementId == null || x.ProviderElementId == ""
        // );

        // if (noProviderElementId.length > 0) {
        //     errors.push("All Driver File Provider Element Id must be populated.");
        // }
    } else if (DeliveryType.ThirdPartySyndicated == deliveryType) {
        // const noProviderElementId = ExportTradeDeskTemplateFields.filter(
        //     x => x.ProviderElementId == null || x.ProviderElementId == ""
        // );
        // if (noProviderElementId.length > 0) {
        //     errors.push("All Driver File Provider Element Id must be populated.");
        // }
    }

    return errors;
};
