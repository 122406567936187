const debug = false;
const logDebug = message => {
    if (debug) {
        console.log(message); // eslint-disable-line no-console
    }
};

export const logTreeSearch = text => {
    logDebug(`logging tree search: ${text}`);
    if (window.ga) {
        window.ga("send", {
            hitType: "event",
            eventCategory: "Tree",
            eventAction: "search",
            eventLabel: text,
        });
    }
};

export const logDataDiscoverySearch = text => {
    logDebug(`logging data discovery search: ${text}`);
    if (window.ga) {
        window.ga("send", {
            hitType: "event",
            eventCategory: "DataDiscovery",
            eventAction: "search",
            eventLabel: text,
        });
    }
};
