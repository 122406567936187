import { haystackMatchesSearchObject } from "./search";
import clone from "../helpers/clone";

//import mergeDeep from '../helpers/mergeDeep';
//import update from 'immutability-helper';

/*

    The shape of state.fields is one top level object with two keys "byId" and "byParent".
    "byId" is the usual associative array with fieldKeys as keys, and objects as values.
    "byParent" is an associative array.  Its keys are fieldKeys, its values are an array of all child nodes with that parent.
    For example, if I want to find all nodes directly under fieldKey 6000, I look for store.getState().fields.byParent[6000], which might list [6100, 6200].
    Then I know that 6100 and 6200 are direct descendents of 6000.

    If I want to find the specific attributes and data for a field, then just look under byId.  To find 6000's data, look in
    store.getState().fields.byId[6000].

    Example:

Here is the shape of state.fields = {
    byId: {
        "1":  {
            "id": 1,
            "sort": 1,
            "parent": "#",
            "text": "Experian",
            "type": null,
            "data": null,
            "created_dt": "2016-12-22T10:33:26.863",
            "companyid": 0,
            "sourcekey": 23,
            "viewid": 4,
            "fieldkey": 1,
            "leaf_description": "Experian’s ConsumerView database gives you the ability to leverage consumer demographic characteristics along with psychographic, lifestyle, behavioral, past purchase information for more than 300 million consumers in over 120 million households.",
            "detail_description": null,
            "isLeaf": false
        },
        "2":  {
            ... text: Auto ...
        },
        "3":  {
            ... text: Demographics ...
        },
        "74":  {
            ... text: CAPE ...
            ... leaf_description: "CAPE(Census Area Projected Estimate) is based upon analysis of ... ",
        },
    },
    byParent: {
        "#": ["1", "42"]
        "1": ["2", "3", "74"]
    },
    tableKey: 0              // The table key we are loading fields for
}
// TableKey is usually 0, but it can be a specific number if we only have some fields loaded (for tablereport)
*/

function fields(state = {}, action) {
    switch (action.type) {
        case "LOAD_FIELDS": {
            if (!action.fields) {
                return state;
            }

            return createFieldsState(action.fields);
        }

        case "RENAME_FIELD_FOLDER": {
            if (!action.folderId || !action.newFolderName) {
                return state;
            }
            const newState = clone(state);
            newState.byFolder[action.folderId].text = action.newFolderName;
            return newState;
        }
        case "DELETE_FIELD_FOLDER": {
            if (!action.folderId) {
                return state;
            }
            const newState = clone(state);
            delete newState.byFolder[action.folderId];
            return newState;
        }
        case "MOVE_FIELD_TO_FOLDER": {
            if (!action.fieldId || !action.newFolderId) {
                return state;
            }
            const newState = clone(state);
            const field = newState.byFolder[action.fieldId];
            field.parent = action.newFolderId;
            newState.byId[field.fieldkey].parent = action.newFolderId;
            return newState;
        }
        case "MOVE_FIELDS_TO_FOLDER": {
            if (!action.fieldIds || action.fieldIds.length == 0 || !action.newFolderId) {
                return state;
            }
            const newState = clone(state);
            for (let i = 0; i < action.fieldIds.length; i++) {
                let fieldId = action.fieldIds[i];
                let field = newState.byId[fieldId];

                field.parent = action.newFolderId;
                newState.byId[field.fieldkey].parent = action.newFolderId;
            }
            return newState;
        }
        case "ADD_TEMP_FIELD": {
            if (!action.field) {
                return state;
            }
            const newState = { ...state };
            const field = action.field;
            newState.byId[action.field.fieldkey] = field;
            newState.byFolder[field.workid] = field;

            if (!(field.parent in newState.byParent)) {
                newState.byParent[field.parent] = [];
            }
            newState.byParent[field.parent].push(field.workid);

            return newState;
        }
        default:
            return state;
    }
}

function createFieldsState(fields, tableKey) {
    const newState = {
        byId: {},
        byParent: {},
        byTableKey: {},
        byFolder: {},
        tableKey,
    };

    for (const workid of Object.keys(fields)) {
        const fieldParent = fields[workid];
        if (!fieldParent) {
            continue;
        }

        if (fieldParent.isLeaf && !(fieldParent.fieldkey in newState.byId)) {
            newState.byId[fieldParent.fieldkey] = fieldParent;

            const tableKey = fieldParent.fieldTableKey;
            if (!(tableKey in newState.byTableKey)) {
                newState.byTableKey[tableKey] = {
                    tableKey,
                    tableName: fieldParent.TableName,
                    visibleTableName: fieldParent.VisibleTableName,
                    tableSchema: fieldParent.TableSchema,
                    fields: [],
                };
            }
            newState.byTableKey[tableKey].fields.push(fieldParent);
        }

        if (!(workid in newState.byFolder)) {
            newState.byFolder[workid] = fieldParent;
        }

        /* Do one sweep of the data and create a parent lookup table on load.
        This should be the most efficient way of traversing the tree. */
        const parent = fieldParent.parent;
        if (!(parent in newState.byParent)) {
            newState.byParent[parent] = [];
        }
        newState.byParent[parent].push(workid);
    }
    return newState;
}

// fieldContainsSearchString - This selector uses the 'fields' state
// Used in tree search - does any of this field's children have the search string (via text)
// Could need to be memoized because of its recursive nature?
export const fieldContainsSearchString = (state, workid, searchString, enableFeatures) => {
    if (searchString == null || searchString == "") {
        return false;
    }

    const childrenKeys = state.byParent[workid] || [];
    for (const childKey of childrenKeys) {
        if (fieldMatchesSearchString(state.byFolder[childKey], searchString, enableFeatures)) {
            return true;
        }
    }
    // Check all of my direct children's names, if any match, return true
    for (const childKey of childrenKeys) {
        if (fieldContainsSearchString(state, childKey, searchString, enableFeatures)) {
            return true;
        }
    }

    // Call the function recursively on my children
    // See if parents include - is this needed?
    return fieldParentContainsSearchString(state, workid, searchString, enableFeatures);
};

export const fieldParentContainsSearchString = (state, workid, searchString, enableFeatures) => {
    const parent = state.byFolder[workid].parent;
    if (!parent || parent == "#") {
        return false;
    }
    if (fieldMatchesSearchString(state.byFolder[parent], searchString, enableFeatures)) {
        return true;
    }
    return fieldParentContainsSearchString(state, parent, searchString, enableFeatures);
};

export const fieldMatchesSearchString = (field, searchString, enableFeatures) => {
    if (!field || !field.text) {
        return false;
    }

    return (
        haystackMatchesSearchObject(field.text, searchString, enableFeatures) ||
        (field.isLeaf &&
            field.leaf_description &&
            haystackMatchesSearchObject(field.leaf_description, searchString, enableFeatures))
    );
};

export const fieldContainsSearchLabels = (state, workid, searchLabels) => {
    if (!searchLabels || searchLabels.length == 0) {
        return false;
    }

    const childrenKeys = state.byParent[workid] || [];
    for (const childKey of childrenKeys) {
        if (fieldHasSearchLabels(state.byFolder[childKey], searchLabels)) {
            return true;
        }
    }

    // Check all of my direct children's names, if any match, return true
    for (const childKey of childrenKeys) {
        if (fieldContainsSearchLabels(state, childKey, searchLabels)) {
            return true;
        }
    }

    // Call the function recursively on my children
    // See if parents include - is this needed?
    return fieldParentContainsSearchLabels(state, workid, searchLabels);
};

export const fieldContainsSearchLabels3 = (state, workid, searchLabels) => {
    if (!searchLabels || searchLabels.length == 0) {
        return false;
    }

    const childrenKeys = state.byParent[workid] || [];
    for (const childKey of childrenKeys) {
        if (fieldHasSearchLabels3(state.byFolder[childKey], searchLabels)) {
            return true;
        }
    }

    // Check all of my direct children's names, if any match, return true
    for (const childKey of childrenKeys) {
        if (fieldContainsSearchLabels3(state, childKey, searchLabels)) {
            return true;
        }
    }

    // Call the function recursively on my children
    // See if parents include - is this needed?
    return fieldParentContainsSearchLabels3(state, workid, searchLabels);
};

export const fieldParentContainsSearchLabels = (state, workid, searchLabels) => {
    const folder = state.byFolder[workid];
    const parent = folder ? folder.parent : null;
    if (!parent || parent == "#") {
        return false;
    }
    if (fieldHasSearchLabels(state.byFolder[parent], searchLabels)) {
        return true;
    }
    return fieldParentContainsSearchLabels(state, parent, searchLabels);
};

export const fieldParentContainsSearchLabels3 = (state, workid, searchLabels) => {
    const folder = state.byFolder[workid];
    const parent = folder ? folder.parent : null;
    if (!parent || parent == "#") {
        return false;
    }
    if (fieldHasSearchLabels3(state.byFolder[parent], searchLabels)) {
        return true;
    }
    return fieldParentContainsSearchLabels3(state, parent, searchLabels);
};

export const fieldHasSearchLabels = (field, searchLabels) => {
    if (!field || !field.FieldLabels) {
        return false;
    }

    return field.FieldLabels.split(", ").some(label => searchLabels.includes(label));
};

export const fieldHasSearchLabels3 = (field, searchLabels) => {
    if (!field || !field.UmbrellaFieldId) {
        return false;
    }
    return field.UmbrellaFieldId != null ? field.UmbrellaFieldId == Number(searchLabels) : false;
};

export default fields;
