import * as React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class TabGate extends React.Component {
    static propTypes = {
        // Passed In
        children: PropTypes.node,
        renderIfFail: PropTypes.Node,
        enabledFeatures: PropTypes.array,
        tabType: PropTypes.number,
        showTabs: PropTypes.bool,
    };

    // static defaultProps = {
    //     tabType: 0,
    //     showTabs: false,
    // };

    isPassFeatureCheck = () => {
        const { enabledFeatures, tabType = 0, showTabs = false } = this.props;
        const passedFeatures = enabledFeatures.includes("display-field-rates");
        const treeTabsMode = enabledFeatures.includes("field-tree-tabs");

        return passedFeatures || (treeTabsMode && showTabs && (tabType == 1 || tabType == 2));
    };

    render() {
        const { children, renderIfFail } = this.props;
        const failCase = renderIfFail != null ? renderIfFail : null;
        return this.isPassFeatureCheck() ? children : failCase;
    }
}

const mapStateToProps = state => ({
    enabledFeatures: state.session.enabledFeatures || [],
});
const mapDispatchToProps = null;
const TabGateC = connect(mapStateToProps, mapDispatchToProps)(TabGate);

export default TabGateC;
