import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { ICompany } from "../types/stores/companyTable";
import { IAppState } from "../types/stores/index";

export const SAVING_COMPANY_DETAILS = "SAVING_COMPANY_DETAILS";
export const LOADING_COMPANY_DETAILS = "LOADING_COMPANY_DETAILS";
export const LOADING_COMPANY_FOLDERS = "LOADING_COMPANY_FOLDERS";
export const LOADING_COMPANY_PARENT_FOLDERS = "LOADING_COMPANY_PARENT_FOLDERS";
export const LOADING_COMPANY_VERTICALS = "LOADING_COMPANY_VERTICALS";
export const LOADING_COMPANY_DESTINATIONS = "LOADING_COMPANY_DESTINATIONS";
export const LOADING_COMPANY_USERS = "LOADING_COMPANY_USERS";
export const LOADING_COMPANY_BY_ID = "LOADING_COMPANY_BY_ID";
export const LOADING_COMPANY_FEATURES = "LOADING_COMPANY_FEATURES";
export const LOADING_COMPANY_SNOWFLAKE_SETTINGS = "LOADING_COMPANY_SNOWFLAKE_SETTINGS";
export const SET_COMPANY_DETAILS = "SET_COMPANY_DETAILS";
export const SET_COMPANY_CREATED_DATE = "SET_COMPANY_CREATED_DATE";
export const SET_COMPANY_UPDATED_DATE = "SET_COMPANY_UPDATED_DATE";
export const SET_COMPANY_SV_CLIENT_KEYS = "SET_COMPANY_SV_CLIENT_KEYS";
export const SET_COMPANY_SV_TABLES = "SET_COMPANY_SV_TABLES";
export const SET_COMPANY_SV_CONTROL = "SET_COMPANY_SV_CONTROL";
export const SET_COMPANY_FOLDER_EXIST = "SET_COMPANY_FOLDER_EXIST";
export const SET_COMPANY_TAPAD_DELIVERY_TYPES = "SET_COMPANY_TAPAD_DELIVERY_TYPES";
export const SET_COMPANY_DESTINATION_DEDUPE_SETTINGS = "SET_COMPANY_DESTINATION_DEDUPE_SETTINGS";
export const SET_COMPANY_VERTICALS = "SET_COMPANY_VERTICALS";
export const SET_COMPANY_DESTINATIONS = "SET_COMPANY_DESTINATIONS";
export const SET_SELECTED_COMPANY = "SET_SELECTED_COMPANY";
export const SET_COMPANY_PDF_CONTENT = "SET_COMPANY_PDF_CONTENT";
export const SET_COMPANY_PDF_FILENAME = "SET_COMPANY_PDF_FILENAME";
export const SET_COMPANY_USERS = "SET_COMPANY_USERS";
export const SET_COMPANY_BY_ID = "SET_COMPANY_BY_ID";
export const SET_DEPLOY_SETTINGS = "SET_DEPLOY_SETTINGS";
export const SET_FLOW_CONTROL_DEPLOY_SETTINGS = "SET_FLOW_CONTROL_DEPLOY_SETTINGS";
export const SET_COMPANY_CURRENT_VERTICAL = "SET_COMPANY_CURRENT_VERTICAL";
export const SET_COMPANY_FEATURES = "SET_COMPANY_FEATURES";
export const SET_COMPANY_RESTRICTED_USERNAME = "SET_COMPANY_RESTRICTED_USERNAME";
export const SET_COMPANY_RESTRICTED_PASSWORD = "SET_COMPANY_RESTRICTED_PASSWORD";
export const SET_COMPANY_SNOWFLAKE_SETTINGS = "SET_COMPANY_SNOWFLAKE_SETTINGS";
export const SET_NOT_ALLOWED_DEPLOY_SNOWFLAKE_TABLES = "SET_NOT_ALLOWED_DEPLOY_SNOWFLAKE_TABLES";
export const LOADING_COMPANY_ACTIVATION_SCHEDULES = "LOADING_COMPANY_ACTIVATION_SCHEDULES";

const myGenericReducer = makeReducerFor("COMPANY", "CompanyID");
export default myGenericReducer;

export const getArrayOfAllCompanies = createSelector(
    (state: IAppState) => state.companies.byId,
    (companiesById: { [companyId: number]: ICompany }): Array<ICompany> => Object.values(companiesById)
);
